import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import HouseIcon from "@material-ui/icons/House";
import CallIcon from "@material-ui/icons/Call";
import PhoneRingIcon from "@material-ui/icons/PhonelinkRing";
import DraftsIcon from "@material-ui/icons/Drafts";
import facebook from "./Images/facebook.png";
import youtube from "./Images/youtube.png";
import insta from "./Images/insta.png";
import linkdin from "./Images/linkdin.png";
import twiter from "./Images/twiter.png";
import { NavHashLink as Link } from "react-router-hash-link";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: "#1e2020",
  },

  footerGrid1: {
    marginTop: "5%",
    // backgroundColor:"pink"
  },
  footerGrid2: {
    marginTop: "5%",
    // backgroundColor:"pink",
    // margin:"0 2% "
  },
  footerGrid3: {
    marginTop: "5%",
    display: "flex",
    justifyContent: "flex-start",
    // backgroundColor:"pink"
  },

  head: {
    fontSize: "20px",
    fontFamily: "Poppins",
    fontWeight: "700",
    color: "white",
  },

  ul: {
    listStyleType: "none",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "12.5px",
    color: "white",
  },

  ulLogo: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "",
    textAlign: "center",
    marginTop: "5%",
    // width:"20rem"
  },

  copyrightCard: {
    width: "fit-content",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: "600",
    color: "white",
    // backgroundColor:"pink"
  },

  copyrightGrid: {
    marginTop: "5%",
    marginBottom: "2%",
    textAlign: "center",
    // backgroundColor:"red",
    display: "flex",
    justifyContent: "center",
  },

  liDiv: {
    marginTop: "4%",
    // backgroundColor:"red",
  },

  li: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
  },

  link: {
    textDecoration: "none",
    color: "white",
    "&:hover": {
      // textDecoration:"underline",
    },
  },

  social: {
    marginLeft: "2%",
  },
}));

function Footer() {
  const classes = useStyles();
  const contact = [
    {
      id: 1,
      icon: <HouseIcon />,
      list: "Suite # 716, 7th floor Park Avenue Shahrah-e-Faisal Karachi",
    },
    { id: 1, icon: <CallIcon />, list: "+92 21 34331335" },
    { id: 1, icon: <DraftsIcon />, list: "info@mutexsystemsltd.com" },
    { id: 1, icon: <DraftsIcon />, list: "hr@mutexsystemsltd.com" },
    { id: 1, icon: <DraftsIcon />, list: "support@mutexsystemsltd.com" },
  ];

  const links = [
    { id: 1, page: "About Us", path: "/#about" },
    { id: 2, page: "Security Framework", path: "/#security" },
    { id: 3, page: "Solution Lifecycle", path: "/#solution" },
    { id: 4, page: "Product Lifecycle", path: "/#product" },
    { id: 5, page: "Clients", path: "/#clients" },
    { id: 6, page: "Services We Offer", path: "/#services" },
    { id: 7, page: "Careers", path: "/#careers" },
  ];

  return (
    <div className={classes.footerContainer}>
      <Grid container justify="center">
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          className={classes.footerGrid1}
        >
          <div>
            <ul className={classes.ul}>
              <div className={classes.head}>Contact Us</div>
              <div className={classes.liDiv}>
                {contact.map((detail, index) => (
                  <li className={classes.li} key={index}>
                    {detail.icon}{" "}
                    <span style={{ marginLeft: "0.5rem" }}>{detail.list}</span>
                  </li>
                ))}
              </div>
            </ul>
          </div>
        </Grid>

        <Grid
          item
          xl={2}
          lg={2}
          md={2}
          sm={12}
          xs={12}
          className={classes.footerGrid2}
        >
          <div>
            <ul className={classes.ul}>
              <div className={classes.head}>Quick Links</div>
              <div className={classes.liDiv}>
                {links.map((pages, index) => (
                  <Link to={pages.path} className={classes.link} key={index}>
                    <motion.li
                      whileHover={{ scale: 1.1 }}
                      key={index}
                      className={classes.li}
                    >
                      {pages.page}{" "}
                    </motion.li>
                  </Link>
                ))}
              </div>
            </ul>
          </div>
        </Grid>

        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          className={classes.footerGrid3}
        >
          <div>
            <ul className={classes.ul}>
              <div className={classes.head} style={{ marginTop: "2.5%" }}>
                Let's Get In Touch
              </div>

              <div className={classes.ulLogo}>
                <motion.span whileHover={{ scale: 1.1 }}>
                  <a
                    href="https://www.facebook.com/mutexsystemsltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} alt="facebook"></img>
                  </a>
                </motion.span>
                <motion.span
                  className={classes.social}
                  whileHover={{ scale: 1.1 }}
                >
                  <a
                    href="https://www.youtube.com/channel/UClMh0TpCNROfu3Ky5uGgDLA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={youtube} alt="youtube" />
                  </a>
                </motion.span>
                <motion.span
                  className={classes.social}
                  whileHover={{ scale: 1.1 }}
                >
                  <a
                    href="https://www.instagram.com/mutexsystemspvtltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={insta} alt="insta" />
                  </a>
                </motion.span>
                <motion.span
                  className={classes.social}
                  whileHover={{ scale: 1.1 }}
                >
                  <a
                    href="https://twitter.com/mutexsystemspvt"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={twiter} alt="twiter" />
                  </a>
                </motion.span>
                <motion.span
                  className={classes.social}
                  whileHover={{ scale: 1.1 }}
                >
                  <a
                    href="https://pk.linkedin.com/company/mutex-systems-ltd?trk=public_profile_topcard_current_company"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkdin} alt="linkdin" />
                  </a>
                </motion.span>
              </div>
            </ul>
          </div>
        </Grid>
      </Grid>
      <Grid container item justify="center">
        <Grid
          item
          xl={6}
          lg={4}
          md={6}
          sm={10}
          xs={10}
          className={classes.copyrightGrid}
        >
          <div className={classes.copyrightCard}>
            Copyright © {new Date().getFullYear()} Mutex Systems PVT. LTD. All
            Rights Reserved
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;

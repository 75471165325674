import React,{useEffect} from "react"
import Footer from "../Footer"
import Mainimg from "../Images/siem-top.jpg"
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Typography } from "@material-ui/core"
import {motion, useAnimation} from "framer-motion"
import {useInView} from "react-intersection-observer"
import bottom from "../Images/bottom.png";
import websecvid from "../Images/video/Siem-1.mp4"
import bullet from '../Images/bullet.png';
import MenuTab from "../MenuTab";
import ImgSlider from "../Parts/ImgSlider";
import LogoMutex from "../Parts/LogoMutex";


const useStyles = makeStyles((theme) => ({
  bgDiv: {
    width: "100%",
    height: "100%",
    backgroundColor:"#1e2020"
  },
  
  maingrid: {
    position:"relative"
  },

  logoPosition: {
    display: "flex",
    // backgroundColor: "green",
    justifyContent: "center",
  },

  pageContainer: {
    backgroundColor: "#1e2020",
  },

     mainheading: {
      fontFamily: "Poppins",
      fontWeight: "600",
      // fontSize: "4.5vw",
      fontSize: "5vmax",
      textAlign: "center",
      color: "#129674",
      backgroundImage:
        "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
      backgroundSize: "100%",
      backgroundRepeat: "repeat",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      MozBackgroundClip: "text",
      mozTextFillColor: "transparent",
    },
     mainHeadCard:{
         backgroundColor:'transparent',
        //  backgroundColor:'pink',
          width:'fit-content',
          alignSelf:'center',
          position:'absolute',
          // marginTop:'4%'
      },

     bgheading: {
      color: "#767574 ",
      fontSize: "6vmax",
      fontFamily: "Poppins",
      fontWeight:"700",
      textTransform: "uppercase",
      textAlign:"center",
      // lineHeight:"7rem"
    },

  bgHeadCard:{
  // backgroundColor:'blue',
  backgroundColor:'transparent',
  opacity:'0.05',
  position:"relative",
  },

  bgHeadGrid: {
  // backgroundColor:'red',
  display: "flex",
  justifyContent:"center"
  },

  firewallContentDiv: {
    // backgroundColor:'blue',
  textAlign: "center",
},

firewallContent2: {
  color: "white",
  fontFamily: "Poppins",
  fontSize: "1.2vmax",
  fontWeight: "600",
  marginTop: "3%",
  maxWidth:"65rem"
},

shadow: {
  //    backgroundColor:'red',
  paddingTop: "50px",
  textAlign: "center ",
  backgroundColor: "#1e2020",
},

videoGrid:{
  // backgroundColor:"red",
},
  
videoHeadGrid:{
  // backgroundColor:"blue",
  height:"90%",
},

parentDivVideo:{
  position:"relative", 
  display:"flex", 
  alignItems:"center", 
  flexDirection:"column",
  // backgroundColor:"orange", 
  marginTop:"20%"
},

videoBgHeadCard:{
  // backgroundColor:"pink",
  position:"absolute"
},
bgheading2: {
  color: "#767574 ",
  fontSize: "4vmax",
  fontFamily: "Poppins",
  fontWeight:"700",
  textTransform: "uppercase",
  textAlign:"center",
  opacity:"0.08"
},

videoMainHeadCard:{
  // backgroundColor:"red",
  // position:"relative",
  width:"100%",
  zIndex:"9"

},
mainheading2: {
  fontFamily: "Poppins",
  fontWeight: "600",
  // fontSize: "4.5vw",
  fontSize: "3.5vmax",
  textAlign: "center",
  color: "#129674",
  backgroundImage:
    "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
  backgroundSize: "100%",
  backgroundRepeat: "repeat",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  mozTextFillColor: "transparent",
},
videoTag: {
  width: "100%",
  height: "100%",
},
  websecheading:{
    height:"15rem",
    width:"30rem"
  }
  ,
  li:{
    color:"white",
    fontSize:"1.2vmax",
    fontFamily:"Poppins",
    fontWeight:"700"
},

unList:{
    listStyleType:'none',
    listStyleImage:`url(${bullet})`,
},

menuPosition:{
  // backgroundColor:"red",
  marginTop: "20%",
  position:"fixed",
  zIndex:"2"
},

  }));
  
  function SecurityInfo() {
    const classes = useStyles();
    const {ref, inView} = useInView();
    const bganimation = useAnimation();  
    const animation = useAnimation();  
    const animation1 = useAnimation(); 
    const animation3 = useAnimation(); 
    const lianimation = useAnimation(); 
  
    useEffect(()=>{
      console.log("use effect hook, inView =", inView);
      
        if(inView){
          bganimation.start({
            y:0,opacity:0.1,
            transition:{
              duration:1, ease:"easeOut", delay:0.4
            }
          });
        }
        if(!inView){
          bganimation.start({y:-100, opacity:0})
        } 
  
        if(inView){
          animation.start({
            y:0,opacity:1,
            transition:{
              duration:1, ease:"easeOut", delay:0.1
            }
          });
        }
        if(!inView){
          animation.start({y:100, opacity:0})
        }  
  
        if(inView){
          animation1.start({
            x:0,opacity:1,
            transition:{
              duration:1, ease:"easeInOut", delay:0.1
            }
          });
        }
        if(!inView){
          animation1.start({x:-100,  opacity:0})
        }  
  
        if(inView){
          animation3.start({
            y:0, opacity:1,
            transition:{
              duration:1.5, ease:"easeInOut"
            }
          });
        }
        if(!inView){
          animation3.start({y:100, opacity:0})
        }  
        if(inView){
          lianimation.start({x:0, opacity:1,
          transition:{
            ease:"easeInOut", duration:1}
        });
        }
        if(!inView){
          lianimation.start({x:-100,opacity:0})
        }        
      console.log("use effect hook, inView =", inView);
    }, [inView, animation, animation1, animation3, lianimation, bganimation]);  

    return (
      <>
      < div className={classes.bgDiv}>
         
         <Grid container className={classes.maingrid}>
           <Grid
             item
             xl={12}
             lg={12}
             md={12}
             sm={12}
             xs={12}
             className={classes.logoPosition}
           >
             <LogoMutex/>
           </Grid>

           <Grid item xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className={classes.menuPosition}>
                <MenuTab/>

            </Grid>
         </Grid>
         <img
           src={Mainimg}
           alt="Lock bg"
           height="100%"
           width="100%"
         />
       </div>
{/* --------------------------------------------------------------------- */}
      
       <div style={{ position: "relative"}}>
       
       <Grid
         container
         direction="column"
         justify="center"
         alignItems="center"
         className={classes.pageContainer}
       >
         <Grid
         ref={ref}
           item
           xl={11}
           lg={11}
           md={11}
           sm={12}
           xs={12}
           className={classes.bgHeadGrid}
         >
           <motion.div animate={bganimation} className={classes.bgHeadCard}>
             <Typography className={classes.bgheading}>Security Information & Event Management (SIEM)</Typography>
           </motion.div>
           <motion.div animate={animation} className={classes.mainHeadCard}>
             <Typography className={classes.mainheading}>Security Information & Event Management (SIEM)</Typography>
           </motion.div>
         </Grid>

         <Grid item xl={10} lg={10} md={11} sm={10} xs={10}>
         <motion.div animate={animation3} className={classes.firewallContentDiv}>
         <p className={classes.firewallContent2}>SIEM provides you with security information management (SIM) and Security event management (SEM) functions into one security management system and gives you data analysis, event correlation, aggregation and reporting as well as log management.

</p>
       </motion.div>
     </Grid>
       </Grid>
       <Grid item>
         <div className={classes.shadow}>
           <img src={bottom} alt="Bottom Shadow" width="100%" />
         </div>
       </Grid>
       </div>
{/* --------------------------------------------------------------------------------- */}
      

       <div style={{backgroundColor:"#1e2020"}}>
         <Grid container xl={12} lg={12} md={12} sm={12} xs={12} justify="center">
           <Grid item xl={5} lg={5} md={10} sm={10} xs={10} className={classes.videoGrid}>
             <div className={classes.videoCard}>
             <video className={classes.videoTag} autoPlay loop muted>
               <source src={websecvid} type="video/mp4" />
             </video>
             </div>
           </Grid>

           <Grid item xl={5} lg={5} md={10} sm={10} xs={10} className={classes.videoHeadGrid}>
            <div className={classes.parentDivVideo}>
              <motion.div initial={{x:200}} transition={{ease:"easeIn", duration:1}} animate={{x:0}} whileHover={{scale:1.1}} className={classes.videoBgHeadCard}>
                <Typography className={classes.bgheading2}>Features of SIEM</Typography>
                </motion.div>
                <motion.div initial={{x:-200}} transition={{ease:"easeIn", duration:1}} animate={{x:0}} whileHover={{scale:1.1}} className={classes.videoMainHeadCard}>
                <Typography className={classes.mainheading2}>
                Features of SIEM
                </Typography>
              </motion.div>

              <div>
                <ul className={classes.unList}>
                      <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Real-Time Log & Data Collection</motion.li>
                      <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Log Correlation & Threat Intelligence</motion.li>
                      <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Real-Time Notification & Alerting</motion.li>
                      <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Prioritization, Analytics & AI</motion.li>
                      <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Reporting & Dashboards</motion.li>
                      <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Security Workflows</motion.li>
                </ul>
              </div>
            </div>
           </Grid>
         </Grid>
       </div>
       {/* --------------------------------------- */}

       <div style={{backgroundColor:"#1e2020"}}>
       <Grid container direction="column" justify="center" alignItems="center" className={classes.productcontainer}>
          
          <Grid item xl={11} lg={11} md={11} sm={12} xs={12} className={classes.bgHeadGrid}>
            <div className={classes.bgHeadCard}>
              <Typography className={classes.bgheading}>Our Esteemed Principals</Typography>
            </div>
            <div className={classes.mainHeadCard}>
              <Typography className={classes.mainheading}>Our Esteemed Principals</Typography>
            </div>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={9} xs={9} >
            <ImgSlider/>
          </Grid>
              <Grid item>
              <div className={classes.shadow}>
                <img src={bottom} alt="Bottom Shadow" width="100%" />
              </div>
              </Grid>
          </Grid>
          <Footer/>
       </div>
   </>
    );
  }
  
  export default SecurityInfo;


import React, { useState } from "react";
import {
  IconButton,
  Drawer,
  Grid,
  Box,
} from "@material-ui/core";
import closecross from "./Images/close.svg"
import MenuIcon from "@material-ui/icons/Menu";
import {makeStyles} from "@material-ui/core/styles";
import bgmenu from "./Videos/matrix.mp4"
import { NavHashLink as Link } from 'react-router-hash-link';
import {motion} from "framer-motion"
// import banner from "./Images/banner.jpg"

const useStyles = makeStyles (theme=> ({
  menuTab:{
    // backgroundColor:"pink",
    position:"relative",
    width:"fit-content",
  },

  menuBtn:{
    marginTop:"30%",
    color:"#a7a2a2",
    fontSize:"20px", 
    [theme.breakpoints.down("sm")]: {
      fontSize:"14px",
      marginTop:"60%"
    },
    fontWeight:"600",
    fontFamily:"Poppins",
  },

  drawerDiv:{
    width:"100vw",
    
  },

  menuGridContainer:{
    // backgroundColor:"pink",
    height:"70vh"
  },

  menuTabList:{
    textAlign:"center",
    fontSize:"26px",
    fontFamily:"Poppins",
    fontWeight:"800",
    color:"white",
    
  },
  menuTabGrid:{
    // backgroundColor:"blue",
    backgroundColor:"transparent",

  },

  crossIcon:{
    backgroundColor:"transparent",
  //  backgroundColor:"red",
   heigth:"auto",
   width:"2rem",
   cursor:"pointer"
  },
  crossIconCard:{
    backgroundColor:"transparent",
    // backgroundColor:"green",
    display:"flex",
    justifyContent:"flex-end",
  },

  videoTag:{

    position:"absolute",
    width:"100%",
    left:"50%",
    top:"50%",
    height: "100%",
    objectFit: "cover",
    zIndex: "-1",
    transform:" translate(-50%, -50%)"
  },

  link:{
    textDecoration:"none"
  }
}))

function MenuTab() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  // const [openTab, setOpenTab] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.menuTab} > 
          <IconButton onClick={handleOpen}  edge="start" color="inherit" aria-label="menu" className={classes.menuBtn}>
            <MenuIcon/> &nbsp;&nbsp;Menu
          </IconButton>

      <Drawer anchor={"left"} variant="temporary" elevation={10} open={open} onClose={() => setOpen(false)} style={{backgroundColor:"black"}}>
       <div className={classes.drawerDiv} >

        
      {/* <img className={classes.videoTag} src={banner} alt="banner"/> */}

       <video className={classes.videoTag} autoPlay loop muted>
          <source src={bgmenu} type="video/webm" />
        </video>

       <Grid container justify="center" style={{marginTop:"3%"}}>
       <Grid item xl={10} lg={10} md={10} sm={11} xs={11}>
            <div className={classes.crossIconCard}>
              <Box className={classes.crossIcon} onClick={handleClose}>
                  <img src={closecross} alt="Cross" height="auto" width="100%" />
              </Box>
              </div>
            </Grid>
            </Grid>

        
        <div>
          <Grid container  direction="row" justify="space-around" alignItems="center" className={classes.menuGridContainer}>
          
           
           <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className={classes.menuTabGrid}> 
              <Link to="/#about" className={classes.link}>
              <motion.div whileHover={{scale:1.2}} className={classes.menuTabList} onClick={handleClose}>About US</motion.div>
              </Link>
            </Grid>
            

             
            <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className={classes.menuTabGrid}>
            <Link to="/#security" className={classes.link}>
              <motion.div whileHover={{scale:1.2}} className={classes.menuTabList} onClick={handleClose}>Security</motion.div>    
            </Link>
            </Grid>
            

           
            <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className={classes.menuTabGrid}>
            <Link to="/#solution" className={classes.link}>
              <motion.div whileHover={{scale:1.2}} className={classes.menuTabList} onClick={handleClose}>Solution</motion.div>
            </Link>
            </Grid>  
            

           
            <Grid item xl={1} lg={1} md={1} sm={12} xs={12}className={classes.menuTabGrid}>
            <Link to="/#product" className={classes.link}>
              <motion.div whileHover={{scale:1.2}} className={classes.menuTabList} onClick={handleClose}>Product Lifecycle</motion.div>
            </Link>
            </Grid>
     
            <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className={classes.menuTabGrid}>
            <Link to="/#clients" className={classes.link}>
              <motion.div whileHover={{scale:1.2}} className={classes.menuTabList} onClick={handleClose}>Clients</motion.div>
            </Link>
            </Grid>
           
            
            <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className={classes.menuTabGrid}>
            <Link to="/#services" className={classes.link}>
              <motion.div whileHover={{scale:1.2}} className={classes.menuTabList} onClick={handleClose}>Services We Offer</motion.div>
            </Link>
            </Grid>

            <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className={classes.menuTabGrid}>
            <Link to="/#careers" className={classes.link}>
              <motion.div whileHover={{scale:1.2}} className={classes.menuTabList} onClick={handleClose}>Careers</motion.div>
            </Link>
            </Grid>
            

          </Grid>
        </div>
        </div>
      </Drawer>

      
    </div>
  );
}

export default MenuTab;

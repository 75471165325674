import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Card} from '@material-ui/core';
import bottom from './Images/bottom.png';
import blog1 from './Images/blog1.png';
import blog2 from './Images/blog2.png';
import blog3 from './Images/blog3.png';
import {useInView} from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';



const useStyles = makeStyles(theme =>({
    productcontainer:{
        backgroundColor:"#1e2020",
       },

       shadow:{
        //    backgroundColor:'red',
           paddingTop:'50px',
          textAlign:'center ',
          backgroundColor:"#1e2020",
          marginTop:'10%'
       },
       heading:{
        width:"fit-content",
        //    backgroundColor:"#1e2020" 
        backgroundColor:'pink'   
       },
       mainheading: {
        fontFamily: "Poppins",
        fontWeight: "600",
        fontSize: "5vmax",
        textAlign: "center",
        color: "#129674",
        backgroundImage:
          "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        MozBackgroundClip: "text",
        mozTextFillColor: "transparent",
      },
       mainHeadCard:{
           backgroundColor:'transparent',
            // backgroundColor:'pink',
            width:'fit-content',
            alignSelf:'center',
            position:'absolute',
        },
       
        bgheading: {
          color: "#767574 ",
          fontSize: "9vmax",
          fontFamily: "Gotham",
          textTransform: "uppercase",
          textAlign:"center"
        },

        bgHeadCard:{
            backgroundColor:'transparent',
            // backgroundColor:'blue',
            opacity:'0.1',
            position:"relative",
        },

        bgHeadGrid: {
            // backgroundColor:'red',
            display: "flex",
            justifyContent:"center"
        },

        
        dots:{
            bottom:'-80%',
            "& li.slick-active button::before": {
            color: '#0f8b66',
            
            },
            "& li": {
            "& button::before": {
            fontSize: theme.typography.pxToRem(18),
            color: "white",
            opacity: 1, 
            },
        }},
        card:{
            position:"relative",
            transition:"0.5s",

            "&:hover":{
              transform:"scale(1.05)"
            }
        },
        cardcontent:{
            position:"absolute",
            bottom:3,
            width:"80%",
            margin:"5%",
        },
// ----------------------------------------------
sliderGrid:{
  padding:'13px',
  backgroundColor:"#1e2020",
  borderRadius:"5px",
  marginTop:"3%"
},

logosDiv:{
  // backgroundColor:"black", 
  height:"2.5rem", 
  width:"8rem", 
  display:"flex", 
  justifyContent:"center"
},

logos:{
  width:"100%",
  height:"100%",
  [theme.breakpoints.down("md")]: {
   width:"85%"
  },
},
// ------------------------------------------------

fwdiv1:{
// backgroundColor:"pink",
display:"flex",
justifyContent: "center",
alignItems:"center",
height:"4rem",
padding:"5px"

},
icona:{
  "&:hover":{
    margin:"2px"

  }
},
   
}))
function Blogs() {
    const classes= useStyles();
    const {ref, inView} = useInView();
  const animation = useAnimation();  
  const animation2 = useAnimation();

  useEffect(()=>{
    console.log("use effect hook, inView =", inView);
    
    if(inView){
      animation.start({
        y:0,opacity:1,
        transition:{
          duration:1, ease:"easeOut", 
        }
      });
    }
    if(!inView){
      animation.start({y:100, opacity:0})
    }  

    if(inView){
      animation2.start({
        y:0,opacity:0.1,
        transition:{
          duration:1, ease:"easeInOut"
        }
      });
    }
    if(!inView){
      animation2.start({y:-20,  opacity:0})
    }  
      
    console.log("use effect hook, inView =", inView);
  }, [inView, animation, animation2]); 
  
    
    return (
        <div id="product">
      <Grid container direction="column" justify="center" alignItems="center" className={classes.productcontainer}>
        <Grid item xl={10} lg={10} md={10} sm={12} xs={12} className={classes.bgHeadGrid}>
            <motion.div ref={ref} animate={animation2} className={classes.bgHeadCard}>
            <Typography className={classes.bgheading}>Blogs</Typography>
            </motion.div>
            <motion.div ref={ref} animate={animation} className={classes.mainHeadCard}>
                <Typography className={classes.mainheading}>Blogs</Typography>
            </motion.div>
        </Grid>
        
        <Grid item container spacing-xl-1 justify="center"
        alignItems="center" xl={12} lg={12} md={9} sm={9} xs={9} className={classes.sliderGrid} >
            <Grid item xl={4} lg={3} md={4} sm={10} xs={10} className={classes.card}>
                <div className={classes.cardimg}>
                    <img src={blog1} alt="blog-1" width="100%"/>
                </div>
                <div className={classes.cardcontent}>
                    <h3 style={{fontSize:"16px",color:"#ffff", fontWeight:"600"}}>Development</h3>
                    <h1 style={{fontSize:"22px",color:"#ffff",  fontWeight:"600"}}>It uses a dictionary of over a combined handful.</h1>
                    <p style={{fontSize:"13px",color:"#ffff",  fontWeight:"400"}}>It has survived not only five centuries typesetting remaining essentially was the release more recent.</p>
                </div>
            </Grid>
            <Grid item xl={4} lg={3} md={4} sm={10} xs={10} className={classes.card} style={{margin:"20px 1%"}}>
                <div className={classes.cardimg}>
                    <img src={blog2} alt="blog-2" width="100%"/>
                </div>
                <div className={classes.cardcontent}>
                    <h3 style={{fontSize:"16px",color:"#ffff", fontWeight:"600"}}>Development</h3>
                    <h1 style={{fontSize:"22px",color:"#ffff",  fontWeight:"600"}}>It uses a dictionary of over a combined handful.</h1>
                    <p style={{fontSize:"13px",color:"#ffff",  fontWeight:"400"}}>It has survived not only five centuries typesetting remaining essentially was the release more recent.</p>
                </div>
            </Grid>
            <Grid item xl={4} lg={3} md={4} sm={10} xs={10} className={classes.card}>
                <div className={classes.cardimg}>
                    <img src={blog3} alt="blog-3" width="100%"/>
                </div>
                <div className={classes.cardcontent}>
                    <h3 style={{fontSize:"16px",color:"#ffff", fontWeight:"600"}}>Development</h3>
                    <h1 style={{fontSize:"22px",color:"#ffff",  fontWeight:"600"}}>It uses a dictionary of over a combined handful.</h1>
                    <p style={{fontSize:"13px",color:"#ffff",  fontWeight:"400"}}>It has survived not only five centuries typesetting remaining essentially was the release more recent.</p>
                </div>
            </Grid>
            <Grid item container xl={9} lg={9} md={12} sm={12} xs={12}>
                <Grid item container  justify="flex-end" >
                  <a style={{color:"#2CA56E", fontWeight:300, textDecoration:"none", display:"flex", justify:"center", alignItems:"center"}} href="https://mutexsystemsltd.com/blog">
                    <h3>Read Article</h3>
                    <ArrowForwardIcon style={{marginLeft:"5px"}} className={classes.icona}/>
                  </a>
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
          <div className={classes.shadow}>
            <img src={bottom} alt="Bottom Shadow" width="100%"/>
          </div>
        </Grid>
      
    </Grid>
 
 
</div>
    )
}

export default Blogs

import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { Link } from 'react-router-dom';
import bottom from "./Images/bottom.png";
import arrow from "./Images/arrows.png";
import {motion, useAnimation} from "framer-motion"
import {useInView} from "react-intersection-observer"


const useStyles = makeStyles(() => ({
  aboutcontainer: {
    backgroundColor: "#1e2020",
    
  },

  shadow: {
    //    backgroundColor:'red',
    paddingTop: "50px",
    textAlign: "center ",
    backgroundColor: "#1e2020",
  },
  heading: {
    width: "fit-content",
    //    backgroundColor:"#1e2020"
    // backgroundColor:  "pink",
  },
  mainheading: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "5vmax",
    textAlign: "center",
    color: "#129674",
    backgroundImage:
      "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
  },
  mainHeadCard: {
    backgroundColor: "transparent",
    // backgroundColor:'pink',
    width: "fit-content",
    alignSelf: "center",
    position: "absolute",
  },

  bgheading: {
    color: "#767574 ",
    fontSize: "8vmax",
    fontFamily: "Gotham",
    textTransform: "uppercase",
    textAlign:"center"
  },

  bgHeadCard: {
    backgroundColor: "transparent",
    // backgroundColor:'blue',
    opacity: "0.1",
    position: "relative",
  },

  bgHeadGrid: {
    // backgroundColor:'red',
    display: "flex",
    justifyContent:"center"
  },

  aboutContentDiv: {
      // backgroundColor:'blue',
    // backgroundColor: "transparent",
    textAlign: "center",
  },

  aboutContent1: {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "2.3vmax",
    fontWeight: "600",
  },

  aboutContent2: {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "1.2vmax",
    fontWeight: "600",
    marginTop: "3%",
    // backgroundColor:"red"
    
  },

  btnLearnMore: {
    background:"linear-gradient(  0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
    color: "aliceblue",
    padding: "0.5vw 2vw",
    fontSize: "1vmax",
    borderRadius: "1vw",
    textTransform: "uppercase",
    fontFamily: "Poppins",
    fontWeight: "600",
    zIndex:"9"
  },

  btnGrid: {
    marginTop: "2%",
  },

  btnImage: {
    height: "4%",
    width: "2.5vw",
    // backgroundColor:"pink"
  },

  arrowImage: {
    marginTop: "2%",
  },
}));


function About() {

  const {ref, inView} = useInView();
  const animation = useAnimation();  
  const animation2 = useAnimation(); 
  const paraanimation = useAnimation(); 
  const btnanimation = useAnimation(); 

  useEffect(()=>{
    console.log("use effect hook, inView =", inView);
    
      if(inView){
        animation.start({
          y:0,opacity:1,
          transition:{
            duration:1, ease:"easeOut", 
          }
        });
      }
      if(!inView){
        animation.start({y:100, opacity:0})
      }  

      if(inView){
        animation2.start({
          y:0,opacity:0.1,
          transition:{
            duration:1, ease:"easeInOut"
          }
        });
      }
      if(!inView){
        animation2.start({y:-20,  opacity:0})
      }  

      if(inView){
        paraanimation.start({
          y:0, opacity:1,
          transition:{
            duration:1, ease:"easeInOut"
          }
        });
      }
      if(!inView){
        paraanimation.start({y:-100,  opacity:0})
      }  

      if(inView){
        btnanimation.start({
          x:0 ,opacity:1, 
          transition:{
            duration:1, ease:"easeOut"
          }
        });
      }
      if(!inView){
        btnanimation.start({x:-200, opacity:0})
      }  
      
      
    console.log("use effect hook, inView =", inView);
  });  



  const classes = useStyles();
  return (
    <div id="about">
      <Grid 
        container
        justify="center"
        direction="column"
        alignItems="center"
        className={classes.aboutcontainer}
       
      >
        <Grid
          item
          xl={8}
          lg={8}
          md={8}
          sm={11}
          xs={11}
          className={classes.bgHeadGrid}
        >
          <motion.div ref={ref}
             animate={animation2}
          className={classes.bgHeadCard}>
            <Typography
            className={classes.bgheading}>About Us</Typography>
          </motion.div>
          <motion.div 
                 ref={ref}
                 animate={animation}
          className={classes.mainHeadCard}>
            <Typography
            className={classes.mainheading}>About Us</Typography>
          </motion.div>
        </Grid>
        {/* <Waveline/> */}

        <Grid item xl={8} lg={8} md={11} sm={10} xs={10}>
          <motion.div
          animate={paraanimation}
          className={classes.aboutContentDiv}>
            <Typography className={classes.aboutContent1}>
              Fusing human intelligence with security
            </Typography>
            <p className={classes.aboutContent2}>
              Our ingenious idea to change the landscape of IT structure was
              conceived in 2008 when we first opened our doors as an in-house
              infrastructure and help desk service provider. Over the years we
              have evolved as the most cost-effective and premier IT consultancy
              due to our deep rooted expertise in technical knowledge, which we
              cater through our customer-centric approach.
            </p>
          </motion.div>
        </Grid>
        <Grid
          item
          xl={11}
          lg={11}
          md={11}
          sm={11}
          xs={11}
          className={classes.btnGrid}
        >
          <motion.div ref={ref} animate={btnanimation} whileHover={{scale:1.1}}>
          <Link to="/learn-more" style={{ textDecoration:"none"}}><Button  className={classes.btnLearnMore}>
            Learn More &nbsp;&nbsp;
            <div className={classes.btnImage}>
              <img
                src={arrow}
                alt="arrow_image"
                height="auto"
                width="100%"
                className={classes.arrowImage}
              />
            </div>
          </Button></Link>
          </motion.div>
          
        </Grid>

        <Grid item>
          <div className={classes.shadow}>
            <img src={bottom} alt="Bottom Shadow" width="100%" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default About;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card, Button } from "@material-ui/core";
import bottom from "./Images/bottom.png";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import {Link} from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  productcontainer: {
    backgroundColor: "#1e2020",
  },

  shadow: {
    //    backgroundColor:'red',
    // paddingTop: "50px",
    textAlign: "center ",
    backgroundColor: "#1e2020",
    marginTop: "10%",
  },
  heading: {
    width: "fit-content",
    //    backgroundColor:"#1e2020"
    backgroundColor: "pink",
  },
  mainheading: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "5vmax",
    textAlign: "center",
    color: "#129674",
    backgroundImage:
      "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
  },
  mainHeadCard: {
    backgroundColor: "transparent",
    // backgroundColor:'pink',
    width: "fit-content",
    alignSelf: "center",
    position: "absolute",
  },

  bgheading: {
    color: "#767574 ",
    fontSize: "7vmax",
    fontWeight: "700",
    fontFamily: "Gotham",
    textTransform: "uppercase",
    textAlign: "center",
  },

  bgHeadCard: {
    backgroundColor: "transparent",
    // backgroundColor:'blue',
    opacity: "0.1",
    position: "relative",
  },

  bgHeadGrid: {
    // backgroundColor:'red',
    display: "flex",
    justifyContent: "center",
  },

// ------------------------------------------------------------------------
 
mainGrid:{
  background: "transparent linear-gradient(255deg, #06A567 0%, #1C8A7E 100%) 0% 0% no-repeat padding-box",
  boxShadow: "1px 34px 37px #00000091",
  borderRadius: "4px"
 },

  headGrid1:{
    marginTop:"4vmax"
  },
  headCard1:{
    fontFamily:"Poppins",
    fontSize:"35px",
    fontWeight:"500",
    textAlign:"center",
    '@media (max-width: 600px)' : {
      fontSize:"22px"
     },
    color:"white",
    lineHeight:"99%"
  },

  hireContentGrid:{
    marginTop:"2vmax"
  },

  hireContentCard: {
    textAlign: "justify",
    fontFamily: "Poppins",
    '@media (max-width: 600px)' : {
      fontSize:"1.7vmax"
     },
     color:"white",
     wordSpacing:"-2px"
    //  backgroundColor:"red"
    
  },
  
  btnGrid:{
    marginTop:"3vmax",
    marginBottom:"2vmax",
    display:"flex",
    justifyContent:"center"
  },
  btn1:{
    color:"white",
    border:"solid 2px black",
    fontFamily: "Poppins",
    backgroundColor:"black",
    borderRadius:"24px",
    padding:"10px 35px",
    '@media (max-width: 600px)' : {
        fontSize:"1.7vmax"
       },
    '&:hover': {
        // backgroundColor: "grey",
        color: "black",
        border:" solid 2px black"
    },
    // '&:active': {
    //     color: "white",
    //     backgroundColor: "#1E90FF"
    // },
},

jobPostingGrid:{
  display:"flex",
  justifyContent:"center",
  '@media (max-width: 780px)' : {
    marginBottom:"2vmax"
   },
},
jobPostingCard:{
  width:"100%",
  background: "#1E2020 0% 0% no-repeat padding-box",
  boxShadow: "0px 4px 21px #575757",
  borderRadius:"14px"
},


jobPositionGrid:{
  marginLeft:"1vmax",
  marginTop:"2vmax"
},
jobPosition:{
  fontSize:"1.7vmax",
  fontFamily: "Poppins",
  color: "#2CA56E",
  fontWeight:"700",
  lineHeight:"95%",
},

jobDepartGrid:{
  marginLeft:"1vmax"
},
jobDepart:{
  fontSize:"1.25vmax",
  fontFamily: "Poppins",
  fontWeight:"700",
  color:"white"
},

jobDescripGrid:{
  marginTop:"2vmax",
  marginBottom:"2vmax",
  marginLeft:"1vmax"
},
jobDescrip:{
  fontFamily: "Poppins",
  fontSize:"1vmax",
  fontWeight:"400",
  // lineHeight:"99%",
  color: "grey"
},

locationGrid:{
  marginLeft:"1vmax"
},
location:{
  fontFamily: "Poppins",
  fontSize:"1vmax",
  fontWeight:"600",
  color:"white"

},

shiftGrid:{
  marginBottom:"2vmax",
  marginLeft:"1vmax"
}


  

}));
function Hiring() {
  const classes = useStyles();
    const {ref, inView} = useInView();
    const animation = useAnimation();
    const animation2 = useAnimation();
    const btnanimation = useAnimation();

    useEffect(()=>{
      console.log("use effect hook, inView =", inView);

        if(inView){
          animation.start({
            y:0,opacity:1,
            transition:{
              duration:1, ease:"easeOut",
            }
          });
        }
        if(!inView){
          animation.start({y:100, opacity:0})
        }

        if(inView){
          animation2.start({
            y:0,opacity:0.1,
            transition:{
              duration:1, ease:"easeInOut"
            }
          });
        }
        if(!inView){
          animation2.start({y:-100,  opacity:0})
        }
        if(inView){
          btnanimation.start({
            y:0,opacity:1,
            transition:{
              duration:1, ease:"easeOut", delay:0.1
            }
          });
        }
        if(!inView){
          btnanimation.start({y:100, opacity:0})
        }  

      console.log("use effect hook, inView =", inView);
    }, [inView, animation, animation2, btnanimation]);

  return (
    <div id="careers">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.productcontainer}
      >
        <Grid
          item
          xl={10}
          lg={10}
          md={10}
          sm={12}
          xs={12}
          className={classes.bgHeadGrid}
        >
          <motion.div animate={animation2} ref={ref} className={classes.bgHeadCard}>
            <Typography className={classes.bgheading}>We Are Hiring</Typography>
          </motion.div>
          <motion.div animate={animation} ref={ref} className={classes.mainHeadCard}>
            <Typography className={classes.mainheading}>
              We Are Hiring
            </Typography>
          </motion.div>
        </Grid>
{/* ---------------------------------------------------------------------------------------------------------- */}
        <Grid
          container
          item
          xl={9}
          lg={9}
          md={9}
          sm={9}
          xs={9}
          className={classes.mainGrid}
          // style={{ backgroundColor: "blue", border: "solid 1px white" }}
        >
          <Grid
            container
            item
            justify="center"
            xl={7}
            lg={7}
            md={7}
            sm={12}
            xs={12}
          >
            <Grid item xl={6} lg={9} md={9} sm={9} xs={9} className={classes.headGrid1}>
              <div className={classes.headCard1}>
              Be A Part of the Most Diversified Team! 
              </div>
            </Grid>

            <Grid item xl={9} lg={9} md={9} sm={9} xs={9} className={classes.hireContentGrid}>
              <div className={classes.hireContentCard}>
              Mutex Systems looks for professionals who fearlessly thrive on new challenges 
              and drive sustainable business growth. Mutex Systems offers an exceptional 
              opportunity to participate in a topnotch candidate experience. 
              <br/>
              <br/>

              Explore our open positions and become a part of the most rewarding journey!   
            </div>
            </Grid>

            <Grid item xl={4} lg={4} md={9} sm={9} xs={9} className={classes.btnGrid}>
            <Link style={{textDecoration:"none"}} to="/career-page">
              <motion.div animate={btnanimation} ref={ref}>
              <Button className={classes.btn1}>
                See All Jobs
              </Button>
              </motion.div>
              </Link>
            </Grid>
          </Grid>
{/* ----------------------------------------------------------------------------------------- */}
          <Grid container justify="center" direction="row" alignItems="center" item xl={5} lg={5} md={5} sm={12} xs={12} style={{backgroundColor:""}}>
            <Grid container item xl={10} lg={10} md={10} sm={9} xs={9} >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.jobPostingGrid}>
                <Card className={classes.jobPostingCard}>

                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.jobPositionGrid}>
                    <div className={classes.jobPosition}>
                      Full Stack Development
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.jobDepartGrid}>
                    <div className={classes.jobDepart}>
                      Product Design
                    </div>
                  </Grid>
                  <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className={classes.jobDescripGrid}>
                    <div className={classes.jobDescrip}>
                      Lorem Ipsum is simply dummy text of the printing and 
                      typesetting industry. Lorem Ipsum has been the industry's 
                      standard dummy text ever since the 1500s,
                    </div>
                  </Grid>
                  <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className={classes.locationGrid}>
                    <div className={classes.location}>
                      Karachi, Pakistan
                    </div>
                  </Grid>
                  <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className={classes.shiftGrid}>
                    <div className={classes.location}>
                      Shift: Morning
                    </div>
                  </Grid>

                </Card>
              </Grid>
              
            </Grid>
            
          </Grid>
        </Grid>
        
{/* ---------------------------------------------------------------------------------------------------- */}
        <Grid item>
          <div className={classes.shadow}>
            <img src={bottom} alt="Bottom Shadow" width="100%" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Hiring;

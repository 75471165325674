import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import {motion, useAnimation} from "framer-motion"
import {useInView} from "react-intersection-observer"
import endpoint1 from "../Images/end-point-top.jpg";
import bottom from "../Images/bottom.png";
import bullet from '../Images/bullet.png';
import endpoint1Video from "../Images/video/endpoint-1.webm"
import Footer from "../Footer";
import MenuTab from "../MenuTab";
import ImgSlider from "../Parts/ImgSlider";
import LogoMutex from "../Parts/LogoMutex";


const useStyles = makeStyles((theme) => ({
  bgDiv: {
    width: "100%",
    height: "100%",
    backgroundColor:"#1e2020"
  },
  
  maingrid: {
    position:"relative"
  },

  logoPosition: {
    display: "flex",
    // backgroundColor: "green",
    justifyContent: "center",
  },
// -----------------------------------------------------------------------------------------
pageContainer: {
  backgroundColor: "#1e2020",
},

   mainheading: {
    fontFamily: "Poppins",
    fontWeight: "600",
    // fontSize: "4.5vw",
    fontSize: "5vmax",
    textAlign: "center",
    color: "#129674",
    backgroundImage:
      "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
  },
   mainHeadCard:{
       backgroundColor:'transparent',
      //  backgroundColor:'pink',
        width:'fit-content',
        alignSelf:'center',
        position:'absolute',
        // marginTop:'4%'
    },

   bgheading: {
    color: "#767574 ",
    fontSize: "6vmax",
    fontFamily: "Poppins",
    fontWeight:"700",
    textTransform: "uppercase",
    textAlign:"center",
    // lineHeight:"7rem"
  },

bgHeadCard:{
// backgroundColor:'blue',
backgroundColor:'transparent',
opacity:'0.05',
position:"relative",
},

bgHeadGrid: {
// backgroundColor:'red',
display: "flex",
justifyContent:"center"
},

ipsContentDiv: {
  // backgroundColor:'blue',
textAlign: "center",
},

ipsContent2: {
color: "white",
fontFamily: "Poppins",
fontSize: "1.2vmax",
fontWeight: "600",
marginTop: "1%",
},

tableContainer:{
    fontFamily:"Poppins",
    fontWeight:"600",
    color:"white",
    marginTop:"3%",
    marginBottom:"3%"
},

tableCard1:{
    border:"thick solid #0e9b70",
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    backgroundColor:"transparent",
    borderRadius:"15px",
    [theme.breakpoints.down('md')]: {
        margin:"2%",
      },
},

tableHead1:{
    backgroundColor:"#0e9b70",
    textAlign:"center",
    width:"100%",
    padding:"15px",
    color:"white",  
    fontSize:"1.5vmax"    
},

tableData1:{
    textAlign:"center",
    padding:"30px",
    color:"white",
    backgroundColor:"transparent",
    fontSize:"1vmax",
},

shadow: {
//    backgroundColor:'red',
paddingTop: "30px",
textAlign: "center ",
backgroundColor: "#1e2020",
},

// ------------------------------------------------------------------------

videoGrid:{
  // backgroundColor:"red",
},
  
videoHeadGrid:{
  // backgroundColor:"blue",
  height:"90%",
},

parentDivVideo:{
  position:"relative", 
  display:"flex", 
  alignItems:"center", 
  flexDirection:"column",
  // backgroundColor:"orange", 
  marginTop:"20%"
},

videoBgHeadCard:{
  // backgroundColor:"pink",
  position:"absolute"
},
bgheading2: {
  color: "#767574 ",
  fontSize: "3.5vmax",
  fontFamily: "Poppins",
  fontWeight:"700",
  textTransform: "uppercase",
  textAlign:"center",
  opacity:"0.08"
},

videoMainHeadCard:{
  // backgroundColor:"red",
  // position:"relative",
  width:"100%",
  zIndex:"9"

},
mainheading2: {
  fontFamily: "Poppins",
  fontWeight: "600",
  // fontSize: "4.5vw",
  fontSize: "3.5vmax",
  textAlign: "center",
  color: "#129674",
  backgroundImage:
    "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
  backgroundSize: "100%",
  backgroundRepeat: "repeat",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  mozTextFillColor: "transparent",
},
videoTag: {
  width: "100%",
  height: "100%",
},

// --------------------------------------------------------

unList:{
  listStyleType:'none',
  listStyleImage:`url(${bullet})`,
},

li:{
  color:"white",
  fontSize:"1.2vmax",
  fontFamily:"Poppins",
  fontWeight:"700"
},


menuPosition:{
  // backgroundColor:"red",
  marginTop: "20%",
  position:"fixed",
  zIndex:"2"
},

shadow2:{
  //    backgroundColor:'red',
     paddingTop:'0px',
    textAlign:'center ',
    backgroundColor:"#1e2020",
    height:'10vh',
    marginTop:""
 },


}));

function EndPointSecurity() {
  const classes = useStyles();
  const {ref, inView} = useInView();
  const bganimation = useAnimation();  
  const animation = useAnimation();  
  const animation1 = useAnimation(); 
  const animation3 = useAnimation();
  useEffect(()=>{
    console.log("use effect hook, inView =", inView);
    
      if(inView){
        bganimation.start({
          y:0,opacity:0.1,
          transition:{
            duration:1, ease:"easeOut", delay:0.4
          }
        });
      }
      if(!inView){
        bganimation.start({y:-100, opacity:0})
      } 

      if(inView){
        animation.start({
          y:0,opacity:1,
          transition:{
            duration:1, ease:"easeOut", delay:0.1
          }
        });
      }
      if(!inView){
        animation.start({y:100, opacity:0})
      }  

      if(inView){
        animation1.start({
          x:0,opacity:1,
          transition:{
            duration:1, ease:"easeInOut", delay:0.1
          }
        });
      }
      if(!inView){
        animation1.start({x:-100,  opacity:0})
      }  

      if(inView){
        animation3.start({
          y:0, opacity:1,
          transition:{
            duration:1.5, ease:"easeInOut"
          }
        });
      }
      if(!inView){
        animation3.start({y:100, opacity:0})
      }  
      
    console.log("use effect hook, inView =", inView);
  }, [inView, animation, animation1, animation3, bganimation]);  

  return (
    <>
        <div className={classes.bgDiv}>
          
          <Grid container className={classes.maingrid}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.logoPosition}
            >
              <LogoMutex/>
            </Grid>

            <Grid item xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className={classes.menuPosition}>
                <MenuTab/>

            </Grid>
          </Grid>
          <img
            src={endpoint1}
            alt="VPN Banner"
            height="100%"
            width="100%"
          />
        </div>
{/* --------------------------------------------------------------------- */}
       
       
<div style={{ position: "relative"}}>
        
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.pageContainer}
        >
          <Grid
            item
            xl={11}
            lg={11}
            md={11}
            sm={12}
            xs={12}
            className={classes.bgHeadGrid}
          >
            <motion.div animate={bganimation} ref={ref} className={classes.bgHeadCard}>
              <Typography className={classes.bgheading}>End Point Security</Typography>
            </motion.div>
        
            <motion.div animate={animation} ref={ref}
              className={classes.mainHeadCard}>
              <Typography className={classes.mainheading}>
              End Point Security
              </Typography>
            </motion.div>
           
          </Grid>

          <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
          <motion.div animate={animation3} ref={ref} className={classes.ipsContentDiv}>
          <p className={classes.ipsContent2}>
          End Point Security is necessary if you want to secure end points or entry points of end-user devices such as desktops, laptops , and mobile devices from being exploited by malicious actors and campaigns. Moreover, it will allow you to protect end points on a network or in the cloud from cybersecurity threats and will make sure to block hackers from targeting high-value assets.
          </p>
          </motion.div>
      </Grid>
        </Grid>

        <Grid item>
          <div className={classes.shadow}>
            <img src={bottom} alt="Bottom Shadow" width="100%"  />
          </div>
        </Grid>
        </div>
{/* ------------------------------------------------------------------------------------------ */}
        <div style={{backgroundColor:"#1e2020"}}>
          <Grid container xl={12} lg={12} md={12} sm={12} xs={12} justify="center">
            <Grid item xl={5} lg={5} md={10} sm={10} xs={10} className={classes.videoGrid}>
              <div className={classes.videoCard}>
              <video className={classes.videoTag} autoPlay loop muted>
                <source src={endpoint1Video} type="video/mp4" />
              </video>
              </div>
            </Grid>

            <Grid item xl={5} lg={5} md={10} sm={10} xs={10} className={classes.videoHeadGrid}>
        
                <div className={classes.parentDivVideo}>
               <motion.div initial={{x:200}} transition={{ease:"easeIn", duration:1}} animate={{x:0}} whileHover={{scale:1.1}} className={classes.videoBgHeadCard}>
                  <Typography className={classes.bgheading2}>Feautures of End Point Security</Typography>
                </motion.div>
                <motion.div initial={{x:-200}} transition={{ease:"easeIn", duration:1}} animate={{x:0}} whileHover={{scale:1.1}} className={classes.videoMainHeadCard}>
                  <Typography className={classes.mainheading2}>
                    Feautures of End Point Security
                  </Typography>
                </motion.div>
                <div style={{marginTop:"3%"}}>
                <ul className={classes.unList}>
                    <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Prevent File-based Malware</motion.li>
                    <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Detect and Block Malicious Activity from Trusted and Untrusted Applications</motion.li>
                    <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Investigate and Remediate to Respond to Dynamic Incidents and Alerts</motion.li>
                    <motion.li initial={{y:40}} animate={{y:0}} transition={{ease:"easeOut", }} whileHover={{color:"#06a567", scale:1.1}} className={classes.li}>Managed Services like monitoring, response and hunting</motion.li>
                </ul>
                </div>
                </div>
              </Grid>
          </Grid>
        </div>
         {/* ------------------------------------------------------------------------------------------ */}
       
         <div>
        
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.pageContainer}
        >
          <Grid
            item
            xl={11}
            lg={11}
            md={11}
            sm={12}
            xs={12}
            className={classes.bgHeadGrid}
          >
            <div className={classes.bgHeadCard}>
              <Typography className={classes.bgheading}>Our Esteemed Principals</Typography>
            </div>
            <div className={classes.mainHeadCard}>
              <Typography className={classes.mainheading}>
              Our Esteemed Principals
              </Typography>
            </div>
          </Grid>

          <Grid item xl={9} lg={9} md={9} sm={9} xs={9} >
        <ImgSlider/>
        </Grid>
        </Grid>

          <Grid item>
              <div className={classes.shadow2}>
              </div>
          </Grid>
          <Grid item>
            <div className={classes.shadow}>
              <img src={bottom} alt="Bottom Shadow" width="100%"  />
            </div>
          </Grid>
        </div>
        {/* ---------------------------------------------------------------------------------------- */}

        <Footer/>
    </>
  );
}

export default EndPointSecurity;

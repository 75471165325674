import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, Button,} from "@material-ui/core";
import career from "../Images/career_img.jpg";
import LogoMutex from "../Parts/LogoMutex";
import MenuTab from "../MenuTab";
// import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
// import Page2 from "./Page2"
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import attachment from "../Images/attachment.png";
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
  bgImgCard: {
    height: "45vmax",
    //    height:"90%",
    position: "relative",
  },

  img: {
    position: "absolute",
  },

  overlay: {
    height: "45vmax",
    width: "100%",
    backgroundColor: "black",
    position: "absolute",
    opacity: "0.8",
  },

  menuPosition:{
    // backgroundColor:"red",
    marginTop: "20%",
    position:"fixed",
    zIndex:"2"
  },

  logoPosition: {
    display: "flex",
    // backgroundColor: "green",
    justifyContent: "center",
  },

  headingGrid:{
    marginTop:"20vmax", 
    [theme.breakpoints.down("md")]: {
        marginTop: "15vmax",
        marginLeft:"2rem"
      },
  },

  line1:{
    background:"transparent linear-gradient(180deg, #06A567 0%, #1C8A7E 100%) 0% 0% no-repeat padding-box",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
    fontSize:"3.5vmax",
    fontFamily:"Poppins",
    fontWeight:"700 "
  },

  line2:{
      color:"white",
      fontSize:"3.5vmax",
      fontFamily:"Poppins",
      fontWeight:"500 ",
      lineHeight:"2rem"
  },

  line3:{
    background:"transparent linear-gradient(180deg, #06A567 0%, #1C8A7E 100%) 0% 0% no-repeat padding-box",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
    fontSize:"4vmax",
    fontFamily:"Poppins",
    fontWeight:"700 ",
    // lineHeight:"1.5vmax",
    lineHeight:"5vmax"
},

line4:{
    fontSize:"2.5vmax",
    fontFamily:"Poppins",
    fontWeight:"700",
    color:"white"
},

span:{
    background:"transparent linear-gradient(180deg, #06A567 0%, #1C8A7E 100%) 0% 0% no-repeat padding-box",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
},

span2:{
    color:"",
    fontWeight:"700"
    // fontFamily:"Poppins"
},

span3:{
    fontWeight:"700",
    // fontFamily:"Poppins"
    background:"transparent linear-gradient(180deg, #06A567 0%, #1C8A7E 100%) 0% 0% no-repeat padding-box",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
},
// ------------------------------------------------------------------------------------
contentCard:{
    fontFamily:"Poppins",   
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    padding:"20px",
    marginBottom:"3rem",
    backgroundColor:"#F6F6F6"
    // height:"15vmax"
},

contentHead:{
    // backgroundColor:"pink", 
    width:"fit-content", 
    height:"fit-content",
    fontSize:"3vmax",
    marginRight:" 1rem",
    textAlign:"justify"
},

contentline:{
    height:"12vmax",
    width:"0.5vmax",
    // border:"4px solid #2CA56E"
    background:"transparent linear-gradient(180deg, #06A567 0%, #1C8A7E 100%) 0% 0% no-repeat padding-box",
    
},

contentParagraph:{
    width:"50%", 
    marginLeft:"1rem", 
    fontSize:"1vmax", 
    display:"flex", 
    flexDirection:"column",
    color:"1e2020",
},
// ------------------------------------------------------------
head1Card: {
    display: "flex",
    justifyContent: "flex-start",
    font: "normal normal bold 3vmax Poppins",
  },

  lineCard: {
    height: "0.5vh",
    width: "3.5rem",
    background: "#1C8A7E",
  },

  span4:{
    background:"transparent linear-gradient(180deg, #06A567 0%, #1C8A7E 100%) 0% 0% no-repeat padding-box",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
  },

  // ------------------------------------------------------------------------------
  jobPostGrid: {
    // backgroundColor:"red",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    padding: "1rem",
  },
  jobPostCard: {
    // height:"16rem",
    width: "22rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    boxShadow: "0px 12px 20px #00000029",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2vmax",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "20rem",
    },
    backgroundColor: "#D8D8D84F"
  },

  jobPostSubCard: {
    width: "100%",
  },
  // -------------------------------------------------
  contentsCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center  ",
  },
  positionCard: {
    color: "#2CA56E",
    font: "normal normal bold 22px/22px Product Sans",
    width: "55%",
  },
  courseIdCard: {
    width: "fit-content",
    height: "fit-content",
    font: "normal normal 700 16px/25px Poppins",
  },

  contentCard2: {
    font: "normal normal 700 14px/21px Poppins",
  },
  contentCard3: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#0075E9",
  },
  contentCard3a: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#E42F2F",
  },

  contentCard4: {
    font: "normal normal normal 14px/17px Poppins",
    marginTop: "1rem",
    marginBottom: "1rem",
    // backgroundColor:"red",
    height: "6rem",
    overflow: "hidden",
    lineClamp: "4",
    display: "-webkit-box",
    boxOrient: "vertical",
  },

  contentCard5: {
    font: "normal normal 700 15px/23px Poppins",
  },

  contentCard6: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
  },
  addBtn2: {
    borderStyle: "none",
    background: "transparent linear-gradient(180deg, #06A567 0%, #1C8A7E 100%)",
    borderRadius: "22px",
    color: "white",
    padding: "0.5rem 1rem",
    font: "normal normal normal 13px/20px Poppins",
    cursor: "pointer",
    textTransform: "Capitalize",
    height: "2rem",
    marginRight: "1rem",
    // fontSize:"1vmax"
  },

  addBtn3: {
    border: "solid 1px #06A567",
    background: "white",
    borderRadius: "22px",
    color: "#06A567",
    padding: "0.5rem 2rem",
    font: "normal normal normal 13px/20px Poppins",
    cursor: "pointer",
    textTransform: "Capitalize",
    height: "2rem",
    
  },
// ------------------------------------------------------
 
topHeading:{
    fontFamily:"Poppins",
    fontWeight:"600",
    fontSize:"2vmax",
    textAlign:"center"
},

span5:{
    fontSize:"1vmax"
},

subHeading:{
    textAlign:'center',
    fontWeight:"500",
    fontSize:"1.5vmax"
},

select:{
    background: "#E5E5E5",
    
},

input:{
    height:"2rem",
    background:" #E5E5E5",
    boxShadow:" 0px 12px 20px #00000029",
    borderRadius:"8px",
    border:"none",
    borderStyle:"none",
    outline:"none",
    fontFamily:"Poppins",
    width:"15rem",
    paddingLeft:"5px",
    "&:active": {
        borderRadius:"8px",
        border:"none",
        borderStyle:"none",
    },
    "&:hover": {
        borderRadius:"8px",
        border:"none",
        borderStyle:"none",
    },
    "&:focus": {
        borderRadius:"8px",
        border:"none",
        borderStyle:"none",
    },
},

inputGrid:{
    display:'flex',
    justifyContent:"center",
    marginBottom:"1rem"
},

textArea:{
    background:" #E5E5E5",
    boxShadow:" 0px 12px 20px #00000029",
    borderRadius:"8px",
    border:"none",
    borderStyle:"none",
    outline:"none",
    fontFamily:"Poppins",
    width:"40vmax",
    padding:"10px",
    "&:active": {
        borderRadius:"8px",
        border:"none",
        borderStyle:"none",
    },
    "&:hover": {
        borderRadius:"8px",
        border:"none",
        borderStyle:"none",
    },
    "&:focus": {
        borderRadius:"8px",
        border:"none",
        borderStyle:"none",
    },
  // zIndex:"10"
},

cvBtn:{
  height:"2rem",
  background:" #E5E5E5",
  boxShadow:" 0px 12px 20px #00000029",
  border:"none",
  borderStyle:"none",
  outline:"none",
  fontFamily:"Poppins",
  width:"14vmax",
  "&:active": {
      border:"none",
      borderStyle:"none",
  },
  "&:hover": {
      border:"none",
      borderStyle:"none",
  },
  "&:focus": {
      border:"none",
      borderStyle:"none",
  },
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  fontSize:"1vmax",
  cursor:"pointer"
},

span6:{
  font: "italic normal normal 1.2vmax Poppins",
  color:"#858585",
  width:"fit-content",
  height:"fit-content",
  // backgroundColor:"red",
},

fileDiv:{
  // backgroundColor:"yellow",
  width:"15vmax", 
  // height:"6rem",
},

attachImg:{
  height:"1rem", 
  width:"1rem",  
  backgroundColor:""
},

// --------------------------------------------------------
modal: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow:"scroll",
  // height:"80rem",
  // width:"80rem"
},
modalCard: {
  height:"30rem", 
  width:"60rem", 
  backgroundColor:"", 
  [theme.breakpoints.down("md")]: {
    overflow:"scroll",
    // height:"40rem"
    },
},
paper: {
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
},

// ----------------------------------------------------------------
btnDiv1:{
  // backgroundColor:"yellow",
  // margin: "0% 2%",
  height: "fit-content",
  display:"flex",
  justifyContent:"flex-end",
  marginTop:"2%",
  marginBottom:"2rem"
},

btnCancel:{
  fontSize:"1vmax",
  width:"fit-content",
  boxShadow: "0px 12px 20px #00000029",
  border: "1px solid #2CA56E",
  borderRadius: "19px",
  opacity: "1",
  textTransform: "capitalize",
  padding:"5px 40px"
},

btnSubmit:{
  fontSize:"1vmax",
  width:"fit-content",
  textTransform: "capitalize",
  background: "transparent linear-gradient(180deg, #06A567 0%, #1C8A7E 100%) 0% 0% no-repeat padding-box",
  boxShadow: "0px 12px 20px #00000029",
  borderRadius: "19px",
  opacity: "1",
  color:"white",
  marginLeft:"5%",
  padding:"5px 40px"

},
}));

function CareerPage() {
  const classes = useStyles();
  const [posts, setPost] = useState("");
  const [form, setForm] = useState("");
  const [open, setOpen] = useState(false);
  const [positionList, setPositionList] = useState("");
  const [cityList, setCityList] = useState("");
  // const [fileName, setFileName] = useState("");  



  const onOpenModal = () =>{
      setOpen(true)
    } ;
  const onCloseModal = () => setOpen(false);
    
  const [First_Name, setFirst_Name] = useState("")
  const [Last_Name, setLast_Name] = useState("")
  const [email, setEmail] = useState("")
  const [city, setCity] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [gender, setGender] = useState("")
  const [position, setPosition] = useState("")
  const [coverLetter, setCoverLetter] = useState("")
  const [resume, setResume] = useState("")

  console.log(First_Name, Last_Name, email, city, phoneNo, gender, position, coverLetter);
  const [mxg, setMessage] = useState("");
  const [error, setError] = useState("");

  const cancelForm = () => {
    document.getElementById("create-form").reset();
    setFirst_Name("");
    setLast_Name("");
    setEmail("");
    setCity("");
    setPhoneNo("");
    setGender("");
    setPosition("");
    setCoverLetter("");
    setResume("");

  }

  const toastSuccess = (tmessage)=>{
    setMessage(toast.success(tmessage, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }));
  }

  const toastError = (tmessage)=>{
    setMessage(toast.error(tmessage, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }));
  }

  const toastWarn = (tmessage)=>{
    setMessage(toast.warn(tmessage, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }));
  }

  // ---------------------------------------Submit Form------------------------------------------------------------
  const submitForm = async () => {
    let formdata = new FormData();
    formdata.append('First_Name', First_Name)
    formdata.append("Last_Name", Last_Name);
    formdata.append("email", email);
    formdata.append("city", city);
    formdata.append("phoneNo", phoneNo);
    formdata.append("gender", gender);
    formdata.append("position", position);
    formdata.append("coverLetter", coverLetter);
    formdata.append("resume", resume);  


    console.log('First_Name', First_Name)
    console.log("Last_Name", Last_Name);
    console.log("email", email);
    console.log("city", city);
    console.log("phoneNo", phoneNo);
    console.log("gender", gender);
    console.log("position", position);
    console.log("coverLetter", coverLetter);
    console.log("resume", resume);  
   
    // const validateEmail = (email) =>{

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    

    // }

    if(First_Name!=="" && Last_Name!=="" && email!=="" && city!=="" && phoneNo!=="" && gender!=="" && position!=="" && coverLetter!=="" && resume )
    {
      const imgext = (resume.name.toString().replace(/^.*\./, '')).toLowerCase();
      if(re.test(String(email).toLowerCase()) && resume.size/(1024 * 1024)<=5 && imgext === "docx" || imgext === "pdf" || imgext === "doc"){
              
              // ---------POST API----------
    try {
     
      const response2 = await fetch(
       "http://192.168.2.204:8081/application/form",
       {
         method: "POST",
         body: formdata,
       }
     );
     console.log("formdata ",formdata)
 // --------------------------------------------------
 
     const jsonData2 = await response2.json();
     setForm(jsonData2);
     console.log("Form List: ", form.message);
     if(form.status === 1){
     toastSuccess("SuccessFully Submited")
         cancelForm();
      }
       else if(form.data[0].value === email){
            toastWarn("This Email is already in use");
          }
      
       // ----------------------------------
         // setError("")
         // console.log("Success Message: ", mxg);
 
     } catch (err) {
       // alert("catch")
       console.error(err.message);
       // if(form.data[0].msg) 
       
       
      
      //  else{
         toastError("Something Went Wrong");
      //  }
       
     }
  

        }else
       {
         if(!re.test(String(email).toLowerCase()))
          {
          alert("email")
          toastError("Email is not Valid");
          }

         
            else if(resume.size / (1024 * 1024)>5)
          {
            toastError("File Size must be Less than 5Mb");
          }
          else if(imgext !=="docx" || imgext !=="doc" || imgext !=="pdf"){
            toastWarn("*This File Extension is not valid");
          }
         
        } 



  }
  else{
    setMessage();
    if(First_Name === ""){
     toastError("*First Name Required");
    }

    else if(Last_Name === ""){
      toastError("*Last Name Required");

    }
    else if(email === ""){
      toastError("*Email Required");

    } 
    else if(city === ""){
      toastError("*City Required");
    } 
    else if(phoneNo === ""){
      toastError("*Phone Number Required");
    }
    else if(gender === ""){
      toastError("*Gender Required");
    }
    else if(position === ""){
      toastError("*Position Required");
    }
    else if(coverLetter === ""){
      toastError("*Cover Letter Required");
    }
    else if(!resume){
      toastError("*Resume Required");
    }
    
  
    

    console.log("Error Message: ", error);

  }


};

// -----------------------Job Post Data--------------------------------------------
       const getPostData = async () => {
        try {
          const response = await fetch(
            "http://192.168.2.204:8081/job/jobPostingWeb",
            {
              method: "GET"
            }
          );
          const jsonData = await response.json();
          setPost(jsonData);
          // console.log("Job Post: ", jsonData);
        
    
        } catch (err) {
          console.error(err.message);
        }
      };

// --------------------------Position List-----------------------------------------------------
  
const getPositionList = async () => {
  try {
    const response3 = await fetch(
      "http://192.168.2.204:8081/add/getposition",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      }
    );
    const jsonData3 = await response3.json();
    setPositionList(jsonData3);
    // console.log("Position idddd: ", positionList);

  } catch (err) {
    console.error(err.message);
  }
};

// --------------------------City List-----------------------------------------------------
  
const getCityList = async () => {
  try {
    const response4 = await fetch(
      "http://192.168.2.204:8081/city/list",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      }
    );
    const jsonData4 = await response4.json();
    setCityList(jsonData4);
    // console.log("Position idddd: ", positionList);

  } catch (err) {
    console.error(err.message);
  }
};

      // console.log("Posting data: ", posts);
      console.log("Form List: ", form);
      // console.log("masjdhasdha: ", form)
      
    
      useEffect(() => {
        const ac = new AbortController();
        getPostData();
        getPositionList();
        getCityList();
        return () => ac?.abort();
      }, []);
    
  return (
    <div style={{ backgroundColor: "", height: "100%", width: "100%" }}>
      <div className={classes.bgImgCard}>
        <img
          src={career}
          alt="bg_img"
          height="100%"
          width="100%"
          className={classes.img}
        />
        <div className={classes.overlay}>hello</div>
        <Grid container style={{position:"absolute"}}>
      <Grid
             item
             xl={12}
             lg={12}
             md={12}
             sm={12}
             xs={12}
             className={classes.logoPosition}
           >    
             <LogoMutex/>
           </Grid>

           <Grid item xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className={classes.menuPosition}>
                <MenuTab/>

            </Grid>

            <Grid container item justify="center" className={classes.headingGrid}>
            <Grid item xl={9}
              lg={9}
              md={9}
              sm={9}
              xs={9} 
              >
                <div>
                    <div className={classes.line1}>Drive Your Career</div>
                    <div className={classes.line2}>And</div>
                    <div className={classes.line3}>Join Us.</div>
                    <div className={classes.line4}>Your Creativity is our <span className={classes.span}>Succes.</span> </div>
                </div>
            </Grid>
            </Grid>
        </Grid>
      </div>
{/* ------------------------------------------------------------------------------------------------------------------ */}
      
      <div>
          <Grid container style={{marginTop:"4rem"}}>
              <Grid item xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12} >
                  <Card className={classes.contentCard}>
                    <div className={classes.contentHead}>
                        <span className={classes.span2}>Why</span> <span className={classes.span3}>Mutex</span>
                    </div>

                    <Card className={classes.contentline}>

                    </Card>

                    <div className={classes.contentParagraph}>
                        <span style={{textAlign:"justify", wordSpacing:"-1px"}}>Mutex Systems Pvt. Ltd., market leading supplier of legacy and innovative IT Solutions. We continuously train and develop the skills of our employees to deliver best services to ensure client requirements are fulfilled adequately. Inclusive work environment, quarterly performance appraisals, exciting rewards and fun activities keeps our employees motivated.</span> 
                        <span style={{marginTop:"5px", marginBottom:"5px"}}>Envision. Embark. Excite. Repeat.</span>  
                        <span>We are currently hiring for the following positions.</span> 
                    </div>
                  </Card>
              </Grid>
          </Grid>
      </div>

{/* ------------------------------------------------------------------------------------------------------------------------------------------------- */}
        <div>
        <Grid container item justify="space-around">
        <Grid
          item
          xl={11}
          lg={11}
          md={11}
          sm={11}
          xs={11}
          className={classes.head1Grid}
        >
          <div className={classes.head1Card}>Current Open &nbsp; <span className={classes.span4}>Positions</span></div>
          <div className={classes.lineCard}></div>
        </Grid>
        </Grid>

        <div style={{display:"flex", justifyContent:"center"}}>
        <Grid container item xl={10} lg={10} md={10} sm={10} xs={10} justify="space-around" style={{ marginTop: "2rem" }}>
        {posts
          ? posts.message.map((data) => (
              <Grid
                item
                xl={4}
                lg={4}
                md={9}
                sm={11}
                xs={11}
                className={classes.jobPostGrid}
                key={data._id}
              >
                <Card className={classes.jobPostCard}>
                  <div className={classes.jobPostSubCard}>
                    <div className={classes.contentsCard}>
                      <div className={classes.positionCard}>
                        {data.Position_Name.position}
                      </div>
                      <div className={classes.courseIdCard}>
                        {data.position_Id}
                      </div>
                    </div>
                    <div className={classes.contentCard2}>
                      {data.department.department}
                    </div>
                    <div
                      className={
                        data.active === false
                          ? classes.contentCard3a
                          : classes.contentCard3
                      }
                    >
                      ({data.active === true ? "Active" : "Expired"})
                    </div>
                    <div className={classes.contentCard4}>
                      <p className={classes.text}>{data.job_Description}</p>
                    </div>
                    <div className={classes.contentCard5}>{data.location}</div>
                    <div className={classes.contentCard5}>
                      Shift: {data.shift}
                    </div>
                    <div className={classes.contentCard6}>
                      <div>
                        <Button 
                            type="button"
                            className={classes.addBtn2}
                        >
                            Full details
                        </Button>

                      </div>
                      
                      <div>
                        <Button
                          className={classes.addBtn3}
                          onClick={onOpenModal}
                        >
                          Apply
                        </Button>           
                      </div>
                    </div>
                  </div>
                </Card>
              </Grid>
            ))
          : null}

              <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={onCloseModal}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                <Fade in={open} className={classes.modalCard}>
                  <div className={classes.paper}>
                      <Card>
                      <Grid container justify="space-around"  style={{marginTop:"1rem"}}>
                                <Grid item xl={12} lg={12} md={12} sm={10} xs={10}>
                                    <div className={classes.topHeading}>
                                    Applying for Full Stack <span className={classes.span}>Development </span> <span className={classes.span5}>(JP-0012)</span>
                                    </div>
                                </Grid>

                                <Grid item xl={9} lg={9} md={9} sm={9} xs={9} style={{marginTop:"2rem"}}>
                                    <div className={classes.subHeading}>
                                    Mutex Solutions QA candidate with 1+ year of proven experience. They should be self-motivated and team player.
                                    </div>
                                </Grid>
                            </Grid>

                            <form id="create-form">
                                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} justify="center" style={{marginTop:"3rem"}}>
                                <Grid item xl={4} lg={4} md={4} sm={7} xs={7} className={classes.inputGrid} style={{backgroundColor:""}}>
                                        <input type="text" placeholder="First Name" name="First_Name" value={First_Name} onChange={e => setFirst_Name(e.target.value)} className={classes.input} />
                                </Grid>
                                <Grid item xl={4} lg={4} md={4} sm={7} xs={7} className={classes.inputGrid} style={{backgroundColor:""}}>      
                                    <input type="text" placeholder="Last Name" name="Last_Name" value={Last_Name} onChange={e => setLast_Name(e.target.value)} className={classes.input} />    
                                </Grid>
                                </Grid>

                                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} justify="center" style={{marginTop:"1rem"}}>
                                <Grid item xl={4} lg={4} md={4} sm={7} xs={7} className={classes.inputGrid} style={{backgroundColor:""}}>
                                        <input type="text" placeholder="Email" name="email" value={email} onChange={e => setEmail(e.target.value)} className={classes.input} />
                                </Grid>
                                <Grid item xl={4} lg={4} md={4} sm={7} xs={7} className={classes.inputGrid} style={{backgroundColor:""}}>      
                                    {/* <input type="text" placeholder="City" name="city" value={city} onChange={e => setCity(e.target.value)} className={classes.input} />     */}
                                    <select className={classes.input} name="city" value={city} onChange={e => setCity(e.target.value)}>
                                        <option value="" disabled>City</option>
                                        {cityList ? cityList.data.map((data, i) => ( 
                                        <option key={i}  value={data._id}>{data.city}</option>
                                        )): null}
                                    </select>  
                                </Grid>
                                </Grid>

                                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} justify="center" style={{marginTop:"1rem"}}>
                                <Grid item xl={4} lg={4} md={4} sm={7} xs={7} className={classes.inputGrid} style={{backgroundColor:""}}>
                                        <input type="text" placeholder="Phone No" name="phoneNo" value={phoneNo} onChange={e => setPhoneNo(e.target.value)} className={classes.input} />
                                </Grid>
                                <Grid item xl={4} lg={4} md={4} sm={7} xs={7} className={classes.inputGrid} style={{backgroundColor:""}}>      
                                    <select className={classes.input} name="gender" value={gender} onChange={e => setGender(e.target.value)}>
                                        <option value="none" disabled>Gender</option> 
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>   
                                </Grid>
                                </Grid>

                                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} justify="center" style={{marginTop:"1rem"}}>
                                <Grid item xl={7} lg={7} md={7} sm={7} xs={7} className={classes.inputGrid} style={{backgroundColor:""}}>      
                                    <select className={classes.input} name="position" value={position} onChange={e => setPosition(e.target.value)}>
                                        <option value="" disabled>Applied Position</option>
                                        {positionList ? positionList.message.map((data, i) => ( 
                                        <option key={i}  value={data._id}>{data.position}</option>
                                        )): null}
                                    </select>   
                                </Grid>
                                <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className={classes.inputGrid} style={{backgroundColor:""}}>
                                        <textarea  rows="8" cols="50"  placeholder="Why You Want to Join Mutex?" name="coverLetter" value={coverLetter} onChange={e => setCoverLetter(e.target.value)} className={classes.textArea} />
                                </Grid>

                                <Grid item xl={8} lg={8} md={8} sm={8} xs={8} className={classes.inputGrid} style={{backgroundColor:""}}>
                                          <div className={classes.fileDiv}>
                                            
                                            <label htmlFor="files" className={classes.cvBtn}>
                                              <div className={classes.attachImg}>
                                              <img src={attachment} alt="attachment" height="100%" width="100%"/>
                                              </div>
                                              Attach Cv/Resume
                                            </label><div>
                                            <span className={classes.span6}>Maximum file size is 5mb</span>
                                            </div>
                                            <input id="files" type="file" style={{display:"none"}}  name="CV" onChange={e => setResume(e.target.files[0])} />
                                          {/* style={{visibility:"hidden"}}  */}
                                            
                                          </div>
                                          
                                        <div style={{ backgroundColor:"",}}  >
                                          {resume ?
                                          <span className={classes.span6}>{resume?.name}</span> :
                                          <span className={classes.span6}>(Allow only PDF and DOC)</span>
                                          }
                                        </div> 
                                </Grid>
                                

                                <Grid 
                                    item
                                    xl={10}
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10} justify="flex-end" style={{backgroundColor:"", marginTop:"3%",}} className={classes.header5}>
                                <div className={classes.btnDiv1}>
                        
                                <Button onClick={onCloseModal} className={classes.btnCancel}>
                                <span className={classes.stack}>Cancel</span>
                                </Button>

                                <Button className={classes.btnSubmit} onClick={()=>submitForm()}>
                                <span>Submit</span>
                                </Button>
                                  <ToastContainer
                                    transition={Flip}
                                  />
                                </div> 
                                </Grid>  
                                </Grid>

                                </form>
                                </Card>
                  </div>
                </Fade>
          </Modal>
      </Grid>
      
      </div>
        </div>    
    </div>
  );
}

export default CareerPage;

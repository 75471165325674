import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
//images import
import fw1 from "../Images/logos/vmware.svg"
import fw2 from "../Images/logos/sangforLogo.svg"
import fw3 from "../Images/logos/trendLogo.svg"
import fw4 from "../Images/logos/symantecLogo.svg"
import fw5 from "../Images/logos/citrix.svg"
import fw6 from "../Images/logos/sonicLogo.svg"
import fw7 from "../Images/logos/forcepointLogo.svg"


const useStyles = makeStyles((theme) => ({

    dots:{
        height:"2vh",
    "& li.slick-active button::before": {
      color: '#0f8b66',
     
    },
    "& li": {
      "& button::before": {
      fontSize: theme.typography.pxToRem(18),
      color: "white",
      opacity: 1, 
    },
  }},

    sliderCard:{
        // backgroundColor:'red',
        width:"70vw",
        },
        
        sliderImgDiv:{
          // backgroundColor:"blue", 
          width:"", 
          height:"4rem",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
        },
        
        logosDiv:{
          // backgroundColor:"black", 
          height:"2.5rem", 
          width:"8rem", 
          display:"flex", 
          justifyContent:"center"
        },
        
        logos:{
          width:"100%",
          height:"100%",
          [theme.breakpoints.down("md")]: {
           width:"85%"
          },
        },
        // ------------------------------------------------
        
        fwdiv1:{
        // backgroundColor:"pink",
        display:"flex",
        justifyContent: "center",
        alignItems:"center",
        height:"4rem",
        padding:"5px",
        },
}));

function ImgSlider() {
  const classes = useStyles();

  const images = [
    { id: "1", image: fw1, alt: "Slider_image" },
    { id: "2", image: fw2, alt: "Slider_image" },
    { id: "3", image: fw3, alt: "Slider_image" },
    { id: "4", image: fw4, alt: "Slider_image" },
    { id: "5", image: fw5, alt: "Slider_image" },
    { id: "6", image: fw6, alt: "Slider_image" },
    { id: "7", image: fw7, alt: "Slider_image" },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 6,
    autoplay: true,
    slidesToScroll: 2,
    adaptiveHeight:true,
    dotsClass: `slick-dots ${classes.dots}`,
    arrows:false,
    responsive: [
    
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          // initialSlide: 2
        }
      },
      
      {
        breakpoint: 240,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <div className={classes.sliderCard}>
      <Slider {...settings}>
        {images.map((data) => (
          <div className={classes.fwdiv1} key={data.id}>
            <div className={classes.sliderImgDiv}>
              <div className={classes.logosDiv}>
                <img
                  src={data.image}
                  alt={data.alt}
                  className={classes.logos}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImgSlider;

import React from "react";
import { Grid, Card, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "./Images/logo-mutex-new.png";
import arrow from "./Images/arrows.png";
import scroll from "./Images/mouse.png";
import Videobg from "./Videobg";
import "../App";
import ParticlesComp from "./Parts/ParticlesComp";
import securetext from "./Images/securetext.png";
import MenuTab from "./MenuTab";
import {Link} from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  logocard: {
    backgroundColor: "transparent",
    // backgroundColor: "pink",
    display: "flex",
    justifyContent: "center",
    border: "none",
    boxShadow: "none",
    marginTop: "1.5%",
    height: "auto",
    width: "20%",
    // transofrm:"rotate(180deg)"
  }
  ,

  logoPosition: {
    display: "flex",
    // backgroundColor:'green',
    justifyContent: "center",
  },

  containerhead: {
    // backgroundColor:'red',
    backgroundColor: "transparent",
    marginTop: "10%",
  },

  headPosition: {
    marginLeft: "20%",
    // backgroundColor: "yellow",
    marginTop: "10%",
    display:"flex"
    // backgroundColor:'transparent'
  },
  slogan: {
    color: "white",
    // backgroundColor: "pink",
    // backgroundColor:'transparent',
    fontFamily: "Poppins",
    fontSize: "1.4vmax",
    border: "none",
    boxShadow: "none",
    width: "fit-content",
    
  },

  verticalhead:{
    height:"auto",
    // backgroundColor:"blue"
  },

  secureDiv:{
    // backgroundColor:"orange",
    height:"90%",
    width:"1.5vw",
    marginTop:"2%",
    //    ["@media (max-width:534px)"]: {
    //   marginTop:"5%",
    // },
    [theme.breakpoints.down("sm")]: {
      marginTop:"5%"
    },
  },

  btnLearnMore: {
    background:
      "linear-gradient(  0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
    color: "aliceblue",
    padding: "0.5vw 2vw",
    fontSize: "1vmax",
    borderRadius: "0.8vw",
    textTransform: "uppercase",
    fontFamily: "Poppins",
    fontWeight: "600",
  },
  btnImage: {
    height: "4%",
    width: "2.5vw",
    // backgroundColor:"pink"
  },

  btnPosition: {
    // backgroundColor:'transparent',
    backgroundColor: "blue",
    marginLeft: "24.5%",
    height: "auto",
  },
  btnCard: {
    backgroundColor: "red",
    width: "fit-content",
    height: "auto",
  },

  tableHead: {
    display: "flex",
    justifyContent: "center",
    fontSize: "10px",
    backgroundColor: "black",
    fontFamily: "Poppins",
    color: "white",
    boxShadow: "0 0 1.5rem 0.5rem rgb(47, 128, 101)",
    marginTop: "2%",
  },

  tableCard: {
    height: "60vh",
    boxShadow: "0 0 1.5rem 0rem rgb(47, 128, 101)",
  },

  maingrid: {
    // backgroundColor:'pink',
    position: "relative",
  },

  scrollGrid: {
    // backgroundColor:'blue',
    marginLeft: "20%",
    marginTop: "1%",
    zIndex:"1000",
    
  },

  scrollImage: {
    width: "2vw",
      //  backgroundColor:'red',
    height: "auto",
    cursor: "pointer"
  },

  menuPosition:{
    // backgroundColor:"red",
    marginTop: "20%",
    position:"fixed",
  },
  
}));


function Header() {
  function handleScroll() {
    window.scrollBy({ 
      top: 200,
      left: 0, 
      behavior: 'smooth' 
    });

    document.querySelector('#about').scrollIntoView({ 
      behavior: 'smooth' 
    });
  }

  const classes = useStyles();
  return (
    <div style={{ position:"relative"}}>
      
      <Videobg />
      {/* <backvideo/> */}
      <Grid container className={classes.maingrid}>
        <ParticlesComp/>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.logoPosition}
        >
          
          <Card className={classes.logocard}>
            <Link to="/">
              <img src={logo} className={classes.logoimg} alt="logo_image" width="100%" height="100%" />
            </Link>
          </Card>
        </Grid>

        <Grid item xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          className={classes.menuPosition}>
      <MenuTab/>

        </Grid>

        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={7}
          xs={10}
          className={classes.headPosition}
        >
          <div className={classes.slogan}>
           
            <h1>Inspired By Innovation </h1> 
            <Button className={classes.btnLearnMore} onClick={handleScroll}>
              Learn More &nbsp;&nbsp;
              <div className={classes.btnImage}>
                <img
                  src={arrow}
                  alt="arrow_image"
                  height="auto"
                  width="100%"
                  className={classes.arrowImage}
                />
              </div>
            </Button>
          </div>

        <div className={classes.secureDiv}>
          <img src={securetext} alt="Secure Innovation" width="100%" height="auto" />
        </div>

        </Grid>

        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={3}
          className={classes.scrollGrid}
        >
          <div onClick={handleScroll} className={classes.scrollImage}>
          <img src={scroll} alt="Scroll img" height="auto" width="100%" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Header;

import React from 'react'

function NotFound() {
    return (
        <div>
            <center><b><h1>NOTFOUND!</h1></b>
    <p>Your Page is Not Found..</p></center>
        </div>
    )
}

export default NotFound

import React, {useEffect} from "react";
import {Grid, Typography, Card} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import bottom from "./Images/bottom.png"
import {Link} from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import bullet from './Images/bullet.png';
import framework from './Images/framework-bg.png'
import {motion, useAnimation} from "framer-motion"
import {useInView} from "react-intersection-observer"
//images import
import fw1 from "./Images/logos/vmware.svg"
import fw2 from "./Images/logos/sangforLogo.svg"
import fw3 from "./Images/logos/trendLogo.svg"
import fw4 from "./Images/logos/symantecLogo.svg"
import fw5 from "./Images/logos/citrix.svg"
import fw6 from "./Images/logos/sonicLogo.svg"
import fw7 from "./Images/logos/forcepointLogo.svg"
// import fw8 from "./Images/framework-8.png"
// import fw9 from "./Images/framework-9.png"
// import fw10 from "./Images/framework-10.png"
// import fw11 from "./Images/framework-11.png"
// import fw12 from "./Images/framework-12.png"


const useStyles = makeStyles (theme=> ({
    aboutcontainer:{
        backgroundColor:"#1e2020",
        
       },

       shadow:{
        //    backgroundColor:'red',
           paddingTop:'50px',
          textAlign:'center ',
          backgroundColor:"#1e2020"
       },
       heading:{
        width:"fit-content",
        //    backgroundColor:"#1e2020" 
        backgroundColor:'pink'   
       },
       mainheading: {
        fontFamily: "Poppins",
        fontWeight: "600",
        // fontSize: "4.5vw",
        fontSize: "4vmax",
        textAlign: "center",
        color: "#129674",
        backgroundImage:
          "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        MozBackgroundClip: "text",
        mozTextFillColor: "transparent",
      },
       mainHeadCard:{
           backgroundColor:'transparent',
        //    backgroundColor:'pink',
            width:'fit-content',
            alignSelf:'center',
            position:'absolute',
        },
       
        bgheading: {
            color: "#767574 ",
            fontSize: "7vmax",
            fontFamily: "Gotham",
            textTransform: "uppercase",
            textAlign:"center"
          },

    bgHeadCard:{
        // backgroundColor:'blue',
        backgroundColor:'transparent',
        opacity:'0.1',
        position:"relative",
    },

    bgHeadGrid: {
        // backgroundColor:'red',
        display: "flex",
        justifyContent:"center"
      },
    headlist:{
        fontFamily:"poppins",
        fontSize:"25px",
        fontWeight:"700",
        backgroundImage: 'linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)',
        backgroundSize: '100%',
        backgroundRepeat: 'repeat',
        WebkitBackgroundClip:'text',
        WebkitTextFillColor:'transparent',
        MozBackgroundClip:'text',
        mozTextFillColor:'transparent',
        marginLeft:"1.5rem",
        // backgroundColor:"yellow",

    },
    list:{
        flexDirection:"column",
        // backgroundColor:"red",
    },

    li:{
        color:"white",
        fontSize:"1.2vmax",
        fontFamily:"Poppins",
        fontWeight:"500",
        width:"fit-content",
        '&:hover': {
          // textDecoration:"underline",
       },
      //  backgroundColor:"pink"


    },

    unList:{
        listStyleType:'none',
        listStyleImage:`url(${bullet})`,
        // backgroundColor:"blue",
        width:"fit-content"
    },
        
    listGrid:{
        marginTop:'5%'
    },

    link:{
      textDecoration:"none"
    },
// ----------------------------------------------
    sliderCard:{
        padding:'9px',
        backgroundImage:`url(${framework})`,
        borderRadius:"5px",
        height:"6rem",    
    },

    sliderImgDiv:{
      // backgroundColor:"blue", 
      width:"9rem", 
      height:"6rem",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",

    },
  
    logos:{
      width:"100%", 
      height:"100%",
      '@media (max-width: 600px)' : {
        width:"82%"
       },
    },


}))

function Security(){

  const {ref, inView} = useInView();
  const bganimation = useAnimation();  
  const animation = useAnimation();  
  const animation2 = useAnimation(); 
  const animation3 = useAnimation(); 
  const animation4 = useAnimation(); 

  useEffect(()=>{
    console.log("use effect hook, inView =", inView);
    
      if(inView){
        bganimation.start({
          y:0,opacity:0.1,
          transition:{
            duration:1, ease:"easeOut", delay:0.4
          }
        });
      }
      if(!inView){
        bganimation.start({y:-100, opacity:0})
      } 

      if(inView){
        animation.start({
          y:0,opacity:1,
          transition:{
            duration:1, ease:"easeOut", delay:0.1
          }
        });
      }
      if(!inView){
        animation.start({y:100, opacity:0})
      }  

      if(inView){
        animation2.start({
          x:0,opacity:1,
          transition:{
            duration:1, ease:"easeInOut", delay:0.1
          }
        });
      }
      if(!inView){
        animation2.start({x:-100,  opacity:0})
      }  

      if(inView){
        animation3.start({
          y:0, opacity:1,
          transition:{
            duration:1.5, ease:"easeInOut"
          }
        });
      }
      if(!inView){
        animation3.start({y:100,  opacity:0})
      }  

      if(inView){
        animation4.start({
          x:0 ,opacity:1,
          transition:{
            duration:1, ease:"easeInOut", delay:0.1
          }
        });
      }
      if(!inView){
        animation4.start({x:90, opacity:0})
      }  
      
      
    console.log("use effect hook, inView =", inView);
  }, [inView, animation, animation2, animation3, animation4, bganimation]);  

    const classes = useStyles();
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 2,
        adaptiveHeight:true,
        dots:false,
        arrows:false,
        responsive: [
    
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          
          {
            breakpoint: 240,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };

      const list1=[
        {id:"1", li:"Next Generation Firewalls", path:"/firewall"},
        {id:"2", li:"VPN", path:"/vpn"},
        {id:"3", li:"IPS/IDS", path:"/ips"},
        {id:"4", li:"DDoS Protection", path:"/ddos"},
        {id:"5", li:"DNS Security", path:"/dns"},
        {id:"6", li:"Network Access Control", path:"/nac"},
        {id:"7", li:"Advanced Threat Protection", path:"/atp"},
        {id:"8", li:"Active Directory Security", path:"/activedir"},
        {id:"9", li:"Cloud Security", path:"/cloudsecurity"},
        {id:"10", li:"Virtualization Security", path:"/virtual"},
      ]
      const list2=[
        {id:"1", li:"Endpoint Security", path:"/end-point-security"},
        {id:"2", li:"Web Security", path:"/web-security"},
        {id:"3", li:"Email Security", path:"/email-security"},
        {id:"4", li:"Data Loss Prevention", path:"/data-loss"},
        {id:"5", li:"Multifactor Authentication", path:"/multifactor"},
        {id:"6", li:"Encryption", path:"/encryption"},
        {id:"7", li:"Priviledged Access Management", path:"/privillage-access-point"},
        {id:"8", li:"Endpoint Detection and Response", path:"/endpointdr"},
      ]
      const list3=[
        {id:"1", li:"Network Assessment", path:"/network-assessment"},
        {id:"2", li:"Web Application Assessment", path:"/webapp"},
        {id:"3", li:"Mobile Application Assessment", path:"/application-security"},
        {id:"4", li:"Penetration Testing", path:"/penetration-testing"},
        {id:"5", li:"SIEM", path:"/security-info"},
        {id:"6", li:"SOAR", path:"/soar"},
        {id:"7", li:"Malware Analysis", path:"/malware"},
        {id:"8", li:"Threat Hunting", path:"threat-hunting"},
        {id:"9", li:"Threat Intelligence", path:"/threat-intelligence"},
        {id:"10", li:"Digital Forensics", path:"/digital-forensic"},
      ]

      const images = [
        {id: "1", image:fw1},
        {id: "2", image:fw2},
        {id: "3", image:fw3},
        {id: "4", image:fw4},
        {id: "5", image:fw5},
        {id: "6", image:fw6},
        {id: "7", image:fw7},
      ]

    return(
        <div id="security">
              <Grid container direction="column" justify="center" alignItems="center" className={classes.aboutcontainer}>
        <Grid ref={ref} item xl={11} lg={11} md={11} sm={12} xs={12} className={classes.bgHeadGrid}>
            <motion.div animate={bganimation} className={classes.bgHeadCard}>
            <Typography className={classes.bgheading}>Framework</Typography>
            </motion.div>
            <motion.div  animate={animation}
             className={classes.mainHeadCard}>
                <Typography className={classes.mainheading}>Elaborated Security Framework</Typography>
            </motion.div>
            
        </Grid>

        <Grid container item xl={11} lg={11} md={11} sm={11} xs={11} justify="center" style={{backgroundColor:"", marginTop:"1rem"}}>
          <Grid item xl={8} lg={7} md={10} sm={11} xs={11}>
            <Card className={classes.sliderCard}>
            <Slider {...settings}>

              {images.map(data =>(
              <div key={data.id}>
              <div className={classes.sliderImgDiv}>
                  <img src={data.image} alt="Slider_Images" className={classes.logos} />
              </div>
              </div>
              ))}

              </Slider>
            </Card>
     
          </Grid>
        </Grid>

        <Grid container item xl={10} lg={10} md={10} sm={10} xs={10} direction="row" justify="center" className={classes.listGrid} >
            <Grid item xl={4} lg={4} md={4} sm={7} xs={11} className={classes.list}>
                <Typography className={classes.headlist}>Infrastructure</Typography>
                  <motion.ul ref={ref} animate={animation2} className={classes.unList}>
                      {
                        list1.map((data)=>(
                          <Link to={data.path} className={classes.link} key={data.id}>
                        <motion.li  whileHover={{scale:1.1}} className={classes.li} >{data.li}</motion.li>
                      </Link>
                        ))
                      }
                  </motion.ul>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={7} xs={11} className={classes.list}>
                <Typography className={classes.headlist}>User and Content</Typography>
                <motion.ul ref={ref} animate={animation3} className={classes.unList}>
                {
                      list2.map((data)=>(
                        <Link to={data.path} className={classes.link} key={data.id}>
                      <motion.li  whileHover={{scale:1.1}} className={classes.li} >{data.li}</motion.li>
                    </Link>
                      ))
                    }
                </motion.ul>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={7} xs={11} className={classes.list}>
                <Typography className={classes.headlist}>SOC & DFIR</Typography>
                <motion.ul ref={ref} animate={animation4} className={classes.unList}>
                {
                      list3.map((data)=>(
                        <Link to={data.path} className={classes.link} key={data.id}>
                      <motion.li  whileHover={{scale:1.1}} className={classes.li} >{data.li}</motion.li>
                    </Link>
                      ))
                    }
                </motion.ul>
            </Grid>
        </Grid>
        
        <Grid item>
            <div className={classes.shadow}>
                <img src={bottom} alt="Bottom Shadow" width="100%"/>
            </div>
        </Grid>
    </Grid>
 

        </div>

    )
}

export default Security;

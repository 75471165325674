import React from 'react'
import Header from './Header'
import About from './About'
import Security from './Security'
import LifeCycle from './LifeCycle'
import ProductLifeCycle from './ProductLifeCycle'
import Clients from './Clients'
import ServiceOffer from './ServiceOffer'
import Footer from './Footer'
import Hiring from './Hiring'
// import CareerPage from './Pages/CareerPage'
import CareerPage from './Pages/CareerPage'
import Blogs from "./Blogs"
// import AboutLM from './Pages/AboutLM'

// import Page4 from './Pages/Page4'


// import Firewalls from './Pages/Firewalls'
// import Websecurity from './Pages/Websecurity'
// import Emailsecurity from './Pages/Emailsecurity'
// import Dataloss from './Pages/Dataloss'
// import Multifactor from './Pages/multifactor'
// import Encryption from './Pages/encryption'
// import PrivilegedAccessPoint from './Pages/privelegeaccess'
// import EndpointDR from './Pages/endpointdr'
// import NetworkAssessment from './Pages/Network'
// import WebApplication from './Pages/WebApp'
// import ApplicationSecurity from './Pages/ApplicationSecurity'
// import PenetrationTesting from './Pages/Penetrationtesting'
// import SecurityInfo from './Pages/SecurityInfo'
// import Soar from './Pages/Soar'
// import Malware from "./Pages/malware"
// import Threathunting from './Pages/ThreatHunting'
// import ThreaIntelligence from './Pages/ThreatIntelligence'
// import DigitalForensic from './Pages/DigitalForensic'
// import VirtualizationSec from './Pages/VirtualizationSecurity'
// import CloudSec from './Pages/CloudSec'
// import Activedirec from "./Pages/Activedirectory"
// import ATP from './Pages/Atp'
// import NAC from './Pages/Nac'
// import Vpn from './Pages/Vpn'
// import IPS from './Pages/IPS'
// import DDos from './Pages/DDos'
// import DnsSecurity from './Pages/DnsSecurity'

function Main() {
    return (
        <div>
           
            <Header/>
            <About/>
            <Security/>
            <LifeCycle/>
            <ProductLifeCycle/>
            <Blogs/>
            <Clients/>
            <ServiceOffer/>
            <Hiring/>
            <Footer/>

            {/* <CareerPage/> */}
            
            {/* <Page4/>     */}
            {/* <AboutLM/>*/}
            

            {/* --- Infra ------- */}
            {/* <Firewalls/>
            <Vpn/>
            <IPS/>
            <DDos/>
            <DnsSecurity/>
            <Websecurity/>
            <VirtualizationSec/>
            <CloudSec/>
            <Activedirec/>
            <ATP/> */}
           


          

            {/* --------------User and Content--------------- */}
                {/* <Websecurity/>
                <Emailsecurity/>
                <Dataloss/>
                <Multifactor/>
                <Encryption/>
                <PrivilegedAccessPoint/>
                <EndpointDR/> */}
                
            {/* ---------SOCDFIR-------- */}
                {/* <NetworkAssessment/>
                <WebApplication/>
                <ApplicationSecurity/>
                <PenetrationTesting/>
                <SecurityInfo/>
                <Soar/>
                <Malware/>
                <Threathunting/>
                <ThreaIntelligence/>
                <DigitalForensic/> */}
        </div>
    )
}

export default Main

import React from 'react';
import './App.css';
import {Routes, Route, HashRouter,} from "react-router-dom"
import Main from './Components/Main';
// import Header from './Components/Header';
import About from './Components/About';
import Security from './Components/Security';
import LifeCycle from './Components/LifeCycle';
import ProductLifeCycle from './Components/ProductLifeCycle';
import Clients from './Components/Clients';
import NotFound from './Components/NotFound';
import ServiceOffer from './Components/ServiceOffer';
// import MenuTab from './Components/MenuTab';
// import Footer from './Components/Footer';
import Firewalls from './Components/Pages/Firewalls';
import Vpn from './Components/Pages/Vpn';
import IPS from './Components/Pages/IPS';
import DDos from './Components/Pages/DDos';
import DnsSecurity from './Components/Pages/DnsSecurity';
import NAC from './Components/Pages/Nac';
import ATP from './Components/Pages/Atp';
import Activedirec from './Components/Pages/Activedirectory';
import CloudSec from './Components/Pages/CloudSec';
import VirtualizationSec from './Components/Pages/VirtualizationSecurity';
import Websecurity from './Components/Pages/Websecurity';
import EndPointSecurity from './Components/Pages/EndPointSecurity';
import Emailsecurity from './Components/Pages/Emailsecurity';
import Dataloss from './Components/Pages/Dataloss';
import Multifactor from './Components/Pages/multifactor';
import Encryption from './Components/Pages/encryption';
import PrivilegedAccessPoint from './Components/Pages/privelegeaccess';
import EndpointDR from './Components/Pages/endpointdr';
import NetworkAssessment from './Components/Pages/Network';
import WebApplication from './Components/Pages/WebApp';
import ApplicationSecurity from './Components/Pages/ApplicationSecurity';
import PenetrationTesting from './Components/Pages/Penetrationtesting';
import SecurityInfo from './Components/Pages/SecurityInfo';
import Soar from './Components/Pages/Soar';
import Malware from './Components/Pages/malware';
import Threathunting from './Components/Pages/ThreatHunting';
import ThreaIntelligence from './Components/Pages/ThreatIntelligence';
import DigitalForensic from './Components/Pages/DigitalForensic';
// import Careers from './Components/Careers';
import Hiring from './Components/Hiring';
import AboutLM from './Components/Pages/AboutLM';
// import Page1 from "./Components/Pages/Page1"
import CareerPage from './Components/Pages/CareerPage';


function App() {
  
  return (
    <HashRouter>
      {/* Main  */}
       <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/security" element={<Security/>}/>
        <Route path="/solution" element={<LifeCycle/>}/>
        <Route path="/product" element={<ProductLifeCycle/>}/>
        <Route path="/clients" element={<Clients/>}/>
        <Route path="/services" element={<ServiceOffer/>}/>
        <Route path="/careers" element={<Hiring/>}/>
        {/* <Route path="/careers" element={<Careers/>}/> */}
        {/* <Route path="/menu" element={<MenuTab/>} />
        <Route render={() => <Navigate to="/" />} /> */}

        {/* ------------------ Pages Routes --------------------------------- */}
        {/* LM Btn Page */}
        <Route path="/learn-more" element={<AboutLM/>}/>
        {/* Infra */}
        <Route path="/firewall" element={<Firewalls/>}/>
        <Route path="/vpn" element={<Vpn/>}/>
        <Route path="/ips" element={<IPS/>}/>
        <Route path="/ddos" element={<DDos/>}/>
        <Route path="/dns" element={<DnsSecurity/>}/>
        <Route path="/nac" element={<NAC/>}/>
        <Route path="/atp" element={<ATP/>}/>
        <Route path="/activedir" element={<Activedirec/>}/>
        <Route path="/cloudsecurity" element={<CloudSec/>}/>
        <Route path="/virtual" element={<VirtualizationSec/>}/>

        {/* Users and Content */}
        <Route path="/end-point-security" element={<EndPointSecurity/>}/>
        <Route path="/web-security" element={<Websecurity/>}/>
        <Route path="/email-security" element={<Emailsecurity/>}/>
        <Route path="/data-loss" element={<Dataloss/>}/>
        <Route path="/multifactor" element={<Multifactor/>}/>
        <Route path="/encryption" element={<Encryption/>}/>
        <Route path="/privillage-access-point" element={<PrivilegedAccessPoint/>}/>
        <Route path="/endpointdr" element={<EndpointDR/>}/>

        {/* SOC & DFIR */}
        <Route path="/network-assessment" element={<NetworkAssessment/>}/>
        <Route path="/webapp" element={<WebApplication/>}/>
        <Route path="/application-security" element={<ApplicationSecurity/>}/>
        <Route path="/penetration-testing" element={<PenetrationTesting/>}/>
        <Route path="/security-info" element={<SecurityInfo/>}/>
        <Route path="/soar" element={<Soar/>}/>        
        <Route path="/malware" element={<Malware/>}/>
        <Route path="/threat-hunting" element={<Threathunting/>}/>
        <Route path="/threat-intelligence" element={<ThreaIntelligence/>}/>
        <Route path="/digital-forensic" element={<DigitalForensic/>}/>

        {/* ---------------------------------------------------------------------*/}

        <Route path="*" element={<NotFound/>}/>
        {/* {Hiring Page} */}

        <Route path="/career-page" element={<CareerPage/>}/> 
      </Routes>
      
    </HashRouter>
  );
}

export default App;

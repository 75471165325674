import React,{useEffect} from "react"
import Footer from "../Footer"
import Mainimg from "../Images/mission-vision-top.jpg"
import vision from "../Images/vision.png"
import focus2 from "../Images/focus2.PNG"
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, Typography } from "@material-ui/core"
import {motion, useAnimation} from "framer-motion"
import {useInView} from "react-intersection-observer"
import MenuTab from "../MenuTab";
import LogoMutex from "../Parts/LogoMutex"


const useStyles = makeStyles((theme) => ({
  bgDiv: {
    width: "100%",
    height: "100%",
    backgroundColor:"#1e2020"
  },
  
  maingrid: {
    position:"relative"
  },

  titleCard: {
    backgroundColor: "transparent",
    // backgroundColor: "pink",
    border: "none",
    boxShadow: "none",
    position:"absolute",
    fontFamily:"Poppins",
    fontSize:"2.8vmax",
    fontWeight:"500",
    color:"white",
    marginTop:"22%",
    width:"60vw",
    [theme.breakpoints.down('xs')]: {
      margin:"18%",
      fontSize:"2vmax",
      width:"70vw"
    }, 
  },

 logoPosition: {
    display: "flex",
    // backgroundColor: "green",
    justifyContent: "center",
  },

  titleGridPosition: {
    display: "flex",
    // backgroundColor: "green",
    justifyContent: "center",
    marginLeft:"20.5%",
  },

  pageContainer: {
    backgroundColor: "#1e2020",
  },

     mainheading: {
      fontFamily: "Poppins",
      fontWeight: "600",
      // fontSize: "4.5vw",
      fontSize: "5vmax",
      textAlign: "center",
      color: "#129674",
      backgroundImage:
        "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
      backgroundSize: "100%",
      backgroundRepeat: "repeat",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      MozBackgroundClip: "text",
      mozTextFillColor: "transparent",
    },
     mainHeadCard:{
         backgroundColor:'transparent',
        //  backgroundColor:'pink',
          width:'fit-content',
          alignSelf:'center',
          position:'absolute',
          // marginTop:'4%'
      },

     bgheading: {
      color: "#767574 ",
      fontSize: "6.4vmax",
      fontFamily: "Poppins",
      fontWeight:"700",
      textTransform: "uppercase",
      textAlign:"center",
      // lineHeight:"7rem"
    },

  bgHeadCard:{
  // backgroundColor:'blue',
  backgroundColor:'transparent',
  opacity:'0.05',
  position:"relative",
  },

  bgHeadGrid: {
  // backgroundColor:'red',
  display: "flex",
  justifyContent:"center"
  },

  firewallContentDiv: {
    // backgroundColor:'blue',
  textAlign: "center",
},
AboutUsLMContent1: {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "2vmax",
    fontWeight: "600",
    marginTop: "3%",
  },
AboutUsLMContent2: {
  color: "white",
  fontFamily: "Poppins",
  fontSize: "1.3vmax",
  fontWeight: "500",
  marginTop: "3%",
},

shadow: {
  //    backgroundColor:'red',
  paddingTop: "50px",
  textAlign: "center ",
  backgroundColor: "#1e2020",
},

websecheading:{
    height:"15rem",
    width:"30rem"
  },

// ------------------ Images ---------------------
fwimgs:{
textAlign:"center",
padding:"25px 25px"
},
fwimgs2:{
textAlign:"center",
padding:"16px 20px"
},
fwimgs3:{
textAlign:"center",
padding:"15px 20px"
},
fwimgs4:{
textAlign:"center",
padding:"15px 20px"
},
fwimgs5:{
textAlign:"center",
padding:"10px 20px"
},
fwimgs6:{
textAlign:"center",
padding:"24px 20px"
},
fwimgs7:{
textAlign:"center",
padding:"20px 20px"
},
// -------------------------------
fwdiv1:{
// backgroundColor:"pink",
alignItems:"center",
// border:"solid" ,
height:"auto",
},

menuPosition:{
  // backgroundColor:"red",
  marginTop: "20%",
  position:"fixed",
  zIndex:"2"
},

dots:{
  height:"2vh",
// bottom:'-80%',
"& li.slick-active button::before": {
color: '#0f8b66',

},
"& li": {
"& button::before": {
fontSize: theme.typography.pxToRem(18),
color: "white",
opacity: 1, 
},

}},

// ----------------------------------------------

aboutCircleGrid:{
  marginTop:"3%",
  dsipaly:"flex", 
  justifyContent:"center",
  alignItems:"center",
  [theme.breakpoints.down('md')]: {
    marginTop:"3%",
    marginBottom:"2%"
  },
  // backgroundColor:"red",
  borderRadius:"50%",
  marginRight:"2rem"
},

threeHeadings:{
  fontFamily:"Poppins",
  fontSize:"3vmax",
  fontWeight:"700",
  color: "#129674",
  backgroundImage:
   "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
  backgroundSize: "100%",
  backgroundRepeat: "repeat",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  mozTextFillColor: "transparent",
},

aboutCircleContent:{
  position:"absolute",
  textAlign:"center"
},

threeParagraph:{
  fontSize:"1.1vmax", 
  textAlign:"center", 
  color:"white"
},

bgHeadCard2:{
// backgroundColor:'blue',
opacity:'0.1',
position:"relative",

},

 mainHeadCard2:{
    //  backgroundColor:'pink',
      width:'fit-content',
      position:'absolute', 
      marginTop:"0.5%",
  },

 bgheading2: {
  color: "#767574 ",
  // fontSize: "6rem",
  fontSize: "6vmax",
  fontFamily: "Poppins",
  fontWeight:"700",
  textTransform: "uppercase",
  textAlign:"center",
},

mainheading2: {
  fontFamily: "Poppins",
  fontWeight: "600",
  // fontSize: "5rem",
  fontSize: "5vmax",
  textAlign: "center",
  color: "#129674",
  backgroundImage:
    "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
  backgroundSize: "100%",
  backgroundRepeat: "repeat",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  MozBackgroundClip: "text",
  mozTextFillColor: "transparent",
},


subContentCard:{
  // backgroundColor:'pink',
  // backgroundColor:'transparent',
  alignSelf:'center',
  color:"white",
  fontFamily:"Poppins",
  display:"flex",
  justifyContent:"center",
  padding:"0px 30px",
  textAlign:"justify",
  fontSize:"1.2vmax"
},

  }));
  
  function AboutLM() {
    const classes = useStyles();
    const {ref, inView} = useInView();
    const bganimation = useAnimation();  
    const animation = useAnimation();
    const animation3 = useAnimation();
  
    useEffect(()=>{
      console.log("use effect hook, inView =", inView);
      
        if(inView){
          bganimation.start({
            y:0,opacity:0.1,
            transition:{
              duration:1, ease:"easeOut", delay:0.4
            }
          });
        }
        if(!inView){
          bganimation.start({y:-100, opacity:0})
        } 
  
        if(inView){
          animation.start({
            y:0,opacity:1,
            transition:{
              duration:1, ease:"easeOut", delay:0.1
            }
          });
        }
        if(!inView){
          animation.start({y:100, opacity:0})
        }   
  
        if(inView){
          animation3.start({
            y:0, opacity:1,
            transition:{
              duration:1.5, ease:"easeInOut"
            }
          });
        }
        if(!inView){
          animation3.start({y:100, opacity:0})
        }  
     
      console.log("use effect hook, inView =", inView);
    }, [inView, animation, animation3, bganimation]);  
  
    return (
      <>
      < div className={classes.bgDiv}>
         
         <Grid container className={classes.maingrid}>
           <Grid
             item
             xl={12}
             lg={12}
             md={12}
             sm={12}
             xs={12}
             className={classes.logoPosition}
           >
             <LogoMutex/>
           </Grid>


            <Grid item xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                className={classes.menuPosition}>
                  <MenuTab/>
              </Grid>

            <Grid
             item
             xl={7}
             lg={7}
             md={7}
             sm={7}
             xs={7}
             className={classes.titleGridPosition}>

             <Card className={classes.titleCard}>
             Reseller, System Integrator and Professional Service Provider
             </Card>
           </Grid>

         </Grid>
         <img
           src={Mainimg}
           alt="Lock bg"
           height="100%"
           width="100%"
          />
           
       </div>


{/* --------------------------------------------------------------------- */}
      
       <div style={{ position: "relative"}}>
       
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.pageContainer}
        >
          <Grid
            ref={ref}
            item
            xl={11}
            lg={11}
            md={11}
            sm={12}
            xs={12}
            className={classes.bgHeadGrid}
          >
            <motion.div animate={bganimation} className={classes.bgHeadCard}>
              <Typography className={classes.bgheading}>About Us</Typography>
            </motion.div>
            <motion.div animate={animation} className={classes.mainHeadCard}>
              <Typography className={classes.mainheading}>About Us</Typography>
            </motion.div>
          </Grid>

          <Grid item xl={7} lg={7} md={7} sm={10} xs={10}>
          <motion.div animate={animation3} className={classes.firewallContentDiv}>
          <p className={classes.AboutUsLMContent1}>Fusing human intelligence with security</p>    
          <p className={classes.AboutUsLMContent2}>
          Mutex Systems Pvt. Ltd. has unleashed its technical potential in optimizing and forging strategic alliances. We intend to build a human-tech relationship, by banking on our expertise in catering E2E enterprise-class solution, to the different industries of Pakistan. We aim to shift the granular dimensions of the IT landscape with our scalable hybrid IT solutions.</p>
          </motion.div>
      </Grid>
        </Grid>
       </div>
{/* --------------------------------------------------------------------------------- */}
      

       <div style={{backgroundColor:"#1e2020"}}>
         <Grid container justify="center">
           <Grid container item xl={3} lg={3} md={8} sm={8} xs={8} className={classes.aboutCircleGrid}>
            <motion.div whileHover={{rotate:360}} style={{position:"relative"}}>
              <img src={vision} alt="vision" height="100%" width="100%"/>
            </motion.div>

            <motion.div whileHover={{scale:1.1}} className={classes.aboutCircleContent}>
            <div style={{width:"18vmax"}}>
              <Typography className={classes.threeHeadings}>Vision</Typography>
            </div>
            <div style={{width:"18vmax"}}>
            <p className={classes.threeParagraph}>Our vision is to amalgamate together the different layers of the 
                  IT infrastructure to enable our associated organizations to pave their 
                  own path of success, growth, and sustainability.</p>
            </div>
            </motion.div>

           </Grid>

           <Grid container item xl={3} lg={3} md={8} sm={8} xs={8} className={classes.aboutCircleGrid}>
            <motion.div whileHover={{rotate:360}} style={{position:"relative"}}>
              <img src={vision} alt="vision" height="100%" width="100%"/>
            </motion.div>

            <motion.div whileHover={{scale:1.1}} className={classes.aboutCircleContent}>
            <div style={{width:"18vmax"}}>
              <Typography className={classes.threeHeadings}>Mission</Typography>
            </div>
            <div style={{width:"18vmax"}}>
            <p className={classes.threeParagraph}>
              We aim to build an alliance with our clients based in 
            the public and private sectors. Mutex intends to transmute the IT structure, 
            by infusing the roots of the client’s organization with our technical expertise.</p>
            </div>
            </motion.div>

           </Grid>

           <Grid container item xl={3} lg={3} md={8} sm={8} xs={8} className={classes.aboutCircleGrid}>
            <motion.div whileHover={{rotate:360}} style={{position:"relative",borderRadius:"50%"}}>
              <img src={vision} alt="vision" height="100%" width="100%"/>
            </motion.div>

            <motion.div whileHover={{scale:1.1}} className={classes.aboutCircleContent}>
            <div style={{width:"18vmax"}}>
              <Typography className={classes.threeHeadings}>Values</Typography>
            </div>
            <div style={{width:"18vmax"}}>
            <p className={classes.threeParagraph}>Our synergy and ideology are layered with a 
            result-oriented approach, a virtuous work ethic and teamwork that allows equality to 
            thrive within the organization.</p>
            </div>
            </motion.div>

           </Grid>

           
         </Grid>
       </div>
       {/* --------------------------------------- */}

       <div style={{backgroundColor:"#1e2020"}}>
       <Grid container justify="" direction="">

         
         <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{marginTop:"2rem", display:"flex", justifyContent:"center", flexDirection:"column", }}>
         <div style={{backgroundColor:"", display:"flex", justifyContent:"center" }}>
            <div animate={bganimation} className={classes.bgHeadCard2}>
                <Typography className={classes.bgheading2}>Who We Are</Typography>
            </div>
            <div animate={animation} className={classes.mainHeadCard2}>
                <Typography className={classes.mainheading2}>Who We Are</Typography>
            </div>
          </div>
          <div className={classes.subContentCard}>
           <p className="">For emerging modern-day enterprises, we focus to structure a technically-permeated roadmap 
           that facilitates the process of making IT investments reliable and easier coupled with an 
           elevated ROI. <br/>
           We along with our partners are able to mitigate the complexities
           circulating in the information technology environment.
           </p>
          </div>
         </Grid>

         <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{marginTop:"2rem", backgroundColor:""}}>
           <div style={{height:"20rem"}}>
              <img src={focus2} alt="focus img" height="100%"  width="100%"/>
           </div>
         </Grid>
         
        </Grid>
        <Footer/>
       </div>
   </>
    );
  }
  
  export default AboutLM;


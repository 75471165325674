import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Grid, Typography } from "@material-ui/core";
import bottom from "./Images/bottom.png";
import cyclevideo from "./Videos/CircleVideo.mp4";
import scroll from "./Images/mouse.png";
import { motion, useAnimation } from "framer-motion";
import {useInView} from "react-intersection-observer"



const useStyles = makeStyles(() => ({
  lifecontainer: {
    backgroundColor: "#1e2020",
  },

  shadow: {
    //    backgroundColor:'red',
    paddingTop: "50px",
    textAlign: "center ",
    backgroundColor: "#1e2020",
  },
  heading: {
    width: "fit-content",
      //  backgroundColor:"#1e2020"
    // backgroundColor: "pink",
  },
  mainheading: {
    fontFamily: "Poppins",
    fontWeight: "600",
    // fontSize: "4.5vw",
    fontSize: "5vmax",
    textAlign: "center",
    color: "#129674",
    backgroundImage:
      "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
  },
  mainHeadCard: {
    // backgroundColor: "transparent",
    // backgroundColor:'pink',
    width: "fit-content",
    alignSelf: "center",
    position: "absolute",
  
  },

  bgheading: {
    color: "#767574 ",
    fontSize: "7vmax",
    fontFamily: "Gotham",
    textTransform: "uppercase",
    textAlign:"center"
  },

  bgHeadCard: {
    // backgroundColor: "transparent",
    // backgroundColor:'blue',
    opacity: "0.1",
    position: "relative",
  },

  bgHeadGrid: {
    // backgroundColor:'red',
    display: "flex",
    justifyContent:"center"
  },

  cycleGrid: {
    //    backgroundColor:'yellow',
  },

  videoTag: {
    width: "100%",
    height: "100%",
  },

  videoParent: {
      //  backgroundColor:'pink',
    marginTop: "1%",
  },

  scroll: {
      //  backgroundColor:'blue',
    marginTop: "25%",
  },

  scrollImage: {
    width: "2vw",
      //  backgroundColor:'red',
    height: "auto",
    cursor: "pointer"
  },
}));
function LifeCycle() {

  function handleScroll() {
    window.scrollBy({ 
      top: 100,
      left: 0, 
      behavior: 'smooth' 
    });

    document.querySelector('#product').scrollIntoView({ 
      behavior: 'smooth' 
    });
  }
  const classes = useStyles();
  
  const {ref, inView} = useInView();
  const animation = useAnimation();  
  const animation2 = useAnimation();

  useEffect(()=>{
    console.log("use effect hook, inView =", inView);
    
    if(inView){
      animation.start({
        y:0,opacity:1,
        transition:{
          duration:1, ease:"easeOut", 
        }
      });
    }
    if(!inView){
      animation.start({y:100, opacity:0})
    }  

    if(inView){
      animation2.start({
        y:0,opacity:0.1,
        transition:{
          duration:1, ease:"easeInOut"
        }
      });
    }
    if(!inView){
      animation2.start({y:-20,  opacity:0})
    }  

      
    console.log("use effect hook, inView =", inView);
  }, [inView,animation, animation2]);  
  return (
    <div id="solution">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.lifecontainer}
      >
        <Grid
          item
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
          className={classes.bgHeadGrid}
        >
          <motion.div ref={ref} animate={animation2} className={classes.bgHeadCard}>
            <Typography className={classes.bgheading}>Solutions</Typography>
          </motion.div>
          <motion.div ref={ref} animate={animation} className={classes.mainHeadCard}>
            <Typography className={classes.mainheading}>
              Solution Lifecycle
            </Typography>
          </motion.div>
        </Grid>

        <Grid
          item
          container
          xl={10}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          className={classes.videoParent}
        >
          <Grid
            item
            xl={10}
            lg={10}
            md={10}
            sm={10}
            xs={10}
            className={classes.cycleGrid}
          >
            <div>
              <video className={classes.videoTag} autoPlay loop muted>
                <source src={cyclevideo} type="video/mp4" />
              </video>
            </div>
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            className={classes.scroll}
          >
            <div className={classes.scrollImage} onClick={handleScroll} >
              <img src={scroll} alt="Scroll img" height="auto" width="100%" />
            </div>
          </Grid>
        </Grid>

        <Grid item>
          <div className={classes.shadow}>
            <img src={bottom} alt="Bottom Shadow" width="100%" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default LifeCycle;

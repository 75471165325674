import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import bottom from './Images/bottom.png';
import Slider from "react-slick";
import fw1 from "./Images/logos/vmware.svg"
import fw2 from "./Images/logos/sangforLogo.svg"
import fw3 from "./Images/logos/trendLogo.svg"  
import fw4 from "./Images/logos/symantecLogo.svg"
import fw5 from "./Images/logos/citrix.svg"
import fw6 from "./Images/logos/sonicLogo.svg"
import fw7 from "./Images/logos/forcepointLogo.svg"
import fw8 from "./Images/product-logos/ibm.png"
import fw9 from "./Images/product-logos/3cx.png"
import fw10 from "./Images/product-logos/alibaba cloud.svg"
import fw11 from "./Images/product-logos/apc.svg"
import fw12 from "./Images/product-logos/amazon-web-logo.png"
import fw13 from "./Images/product-logos/Asset 5.svg"
import fw14 from "./Images/product-logos/cisco-2.svg"
import fw15 from "./Images/product-logos/cyberark-logo.png"
import fw16 from "./Images/product-logos/Cylance.png"
import fw17 from "./Images/product-logos/dell-emc-logo.svg"
import fw18 from "./Images/product-logos/f5.svg"
import fw19 from "./Images/product-logos/google clioud.svg"
import fw20 from "./Images/product-logos/huawei.jpg"
import fw21 from "./Images/product-logos/juniper-networks-logo-4.png"
import fw22 from "./Images/product-logos/kaspersky-lab-1.svg"
import fw23 from "./Images/product-logos/lenovo-logo-2015.svg"
import fw24 from "./Images/product-logos/mcafee-logo-2017-.svg"
import fw25 from "./Images/product-logos/microsoft.svg"
import fw26 from "./Images/product-logos/netapp-logo.png"
import fw27 from "./Images/product-logos/palalto.svg"
import fw28 from "./Images/product-logos/redhat-logo.png"
import fw29 from "./Images/product-logos/Rapid.png"
import fw30 from "./Images/product-logos/rsa-security-1.svg"
import fw33 from "./Images/product-logos/secureatuh.png"
import fw36 from "./Images/product-logos/veeam-seeklogo.png"
import fw37 from "./Images/product-logos/vertias.svg"


import {useInView} from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion";



const useStyles = makeStyles(theme =>({
    productcontainer:{
        backgroundColor:"#1e2020",
       },

       shadow:{
        //    backgroundColor:'red',
           paddingTop:'50px',
          textAlign:'center ',
          backgroundColor:"#1e2020",
          marginTop:'10%'
       },
       heading:{
        width:"fit-content",
        //    backgroundColor:"#1e2020" 
        backgroundColor:'pink'   
       },
       mainheading: {
        fontFamily: "Poppins",
        fontWeight: "600",
        fontSize: "5vmax",
        textAlign: "center",
        color: "#129674",
        backgroundImage:
          "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        MozBackgroundClip: "text",
        mozTextFillColor: "transparent",
      },
       mainHeadCard:{
           backgroundColor:'transparent',
            // backgroundColor:'pink',
            width:'fit-content',
            alignSelf:'center',
            position:'absolute',
        },
       
        bgheading: {
          color: "#767574 ",
          fontSize: "9vmax",
          fontFamily: "Gotham",
          textTransform: "uppercase",
          textAlign:"center"
        },

   bgHeadCard:{
    backgroundColor:'transparent',
    // backgroundColor:'blue',
    opacity:'0.1',
    position:"relative",
   },

   bgHeadGrid: {
    // backgroundColor:'red',
    display: "flex",
    justifyContent:"center"
  },

   
   dots:{
    bottom:'-80%',
    "& li.slick-active button::before": {
      color: '#0f8b66',
     
    },
    "& li": {
      "& button::before": {
      fontSize: theme.typography.pxToRem(18),
      color: "white",
      opacity: 1, 
    },
  }},

// ----------------------------------------------
sliderGrid:{
  padding:'13px',
  backgroundColor:"#1e2020",
  borderRadius:"5px",
  marginTop:"2%"
},
sliderCard:{
// backgroundColor:'red',
width:"70vw",
},

sliderImgDiv:{
  // backgroundColor:"blue", 
  height:"4rem",
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
},

logosDiv:{
  // backgroundColor:"black", 
  height:"2.5rem", 
  width:"8rem", 
  display:"flex", 
  justifyContent:"center"
},

logos:{
  width:"100%",
  height:"100%",
  [theme.breakpoints.down("md")]: {
   width:"85%"
  },
},
// ------------------------------------------------

fwdiv1:{
// backgroundColor:"pink",
display:"flex",
justifyContent: "center",
alignItems:"center",
height:"4rem",
padding:"5px"

},
   
}))
function ProductLifeCycle() {
    const classes= useStyles();
    const {ref, inView} = useInView();
  const animation = useAnimation();  
  const animation2 = useAnimation();
  const images = [
    {id: "1", image:fw1},
    {id: "2", image:fw2},
    {id: "3", image:fw3},
    {id: "4", image:fw4},
    {id: "5", image:fw5},
    {id: "6", image:fw6},
    {id: "7", image:fw7},
    {id: "8", image:fw8},
    {id: "9", image:fw9},
    {id: "10", image:fw10},
    {id: "11", image:fw11},
    {id: "12", image:fw12},
    {id: "13", image:fw13},
    {id: "14", image:fw14},
    {id: "15", image:fw15},
    {id: "16", image:fw16},
    {id: "17", image:fw17},
    {id: "18", image:fw18},
    {id: "19", image:fw19},
    {id: "20", image:fw20},
    {id: "21", image:fw21},
    {id: "22", image:fw22},
    {id: "23", image:fw23},
    {id: "24", image:fw24},
    {id: "25", image:fw25},
    {id: "26", image:fw26},
    {id: "27", image:fw27},
    {id: "28", image:fw28},
    {id: "29", image:fw29},
    {id: "30", image:fw30},
    {id: "33", image:fw33},
    {id: "36", image:fw36},
    {id: "37", image:fw37},
  ]
  useEffect(()=>{
    console.log("use effect hook, inView =", inView);
    
    if(inView){
      animation.start({
        y:0,opacity:1,
        transition:{
          duration:1, ease:"easeOut", 
        }
      });
    }
    if(!inView){
      animation.start({y:100, opacity:0})
    }  

    if(inView){
      animation2.start({
        y:0,opacity:0.1,
        transition:{
          duration:1, ease:"easeInOut"
        }
      });
    }
    if(!inView){
      animation2.start({y:-20,  opacity:0})
    }  
      
    console.log("use effect hook, inView =", inView);
  }, [inView, animation, animation2]); 
  
  
    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 6,
        autoplay: true,
        slidesToScroll: 2,
        adaptiveHeight:true,
        dotsClass: `slick-dots ${classes.dots}`,
        arrows:true,
        responsive: [
    
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          
          {
            breakpoint: 240,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };
    return (
        <div id="product">
      <Grid container direction="column" justify="center" alignItems="center" className={classes.productcontainer}>
        <Grid item xl={10} lg={10} md={10} sm={12} xs={12} className={classes.bgHeadGrid}>
            <motion.div ref={ref} animate={animation2} className={classes.bgHeadCard}>
            <Typography className={classes.bgheading}>Product</Typography>
            </motion.div>
            <motion.div ref={ref} animate={animation} className={classes.mainHeadCard}>
                <Typography className={classes.mainheading}>Product Lifecycle</Typography>
            </motion.div>
        </Grid>
        
        <Grid item xl={9} lg={9} md={9} sm={9} xs={9} className={classes.sliderGrid} >
        <div className={classes.sliderCard}>
        <Slider {...settings}>

        {images.map(data =>(
      <div className={classes.fwdiv1} key={data.id}>
        <div className={classes.sliderImgDiv}>
          <div className={classes.logosDiv}>
            <img src={data.image} alt="Slider_Images" className={classes.logos} />
          </div>
        </div>
      </div>
      ))}

    </Slider>
        </div>
        </Grid>
        <Grid item>
          <div className={classes.shadow}>
            <img src={bottom} alt="Bottom Shadow" width="100%"/>
          </div>
        </Grid>
      
    </Grid>
 
 
</div>
    )
}

export default ProductLifeCycle

import React, {useEffect}  from 'react';
import {Grid, } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {motion, useAnimation} from "framer-motion"
import {useInView} from "react-intersection-observer"


const useStyles = makeStyles ((theme) => ({

    serviceContainer:{
        // backgroundColor:"#1e2020",
        // height:'100vh',
        background: 'linear-gradient(0deg, rgb(6,165,102) 10%, rgb(28,138,126) 100%)',
        
    },

    circleCard:{
      // backgroundColor:"red", 
      borderRadius:"50rem", 
      height:"18vmax", 
      width:"18vmax",
      float:"right",
      display:"flex",
      alignItems:"center",
      color:"white",
      justifyContent:"center",
      boxShadow:"10px 10px 25px black",
      fontFamily:'Poppins',
      [theme.breakpoints.down("sm")]: {
        padding:"8px"
      },
    },

    circleCard2:{
      // backgroundColor:"pink", 
      borderRadius:"50rem", 
      height:"18vmax", 
      width:"18vmax",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      fontFamily:'Poppins',
      boxShadow:"10px 10px 25px black",
      color:"white",
      [theme.breakpoints.down("sm")]: {
        padding:"8px"
      },
    },

    circleGrid:{
      // backgroundColor:"grey", 
      marginBottom:"1rem", 
    },

    circleGrid2:{
      // backgroundColor:"silver", 
      marginBottom:"1rem",
      marginLeft:"1rem"
    },

    serviceContentGrid:{
      [theme.breakpoints.down("md")]: {
        marginBottom:"3rem"
      },
    },

    serviceHead:{
      width:"fit-content",
      fontSize:'8vmin',
      fontFamily:'Poppins',
      fontWeight:'600',
      color:'white', 
    },

    serviceContent:{
      fontSize:'2vh',
        fontFamily:'Poppins',
        fontWeight:'700',
      color:'white', 
      marginTop:"1rem",
    },




  


}))
function ServiceOffer() {
    const classes = useStyles();
    const {ref, inView} = useInView();
    const headinganimation = useAnimation();  
    const paraanimation = useAnimation();  
    const slaanimation = useAnimation(); 
    const MSSanimation = useAnimation(); 
    const PSanimation = useAnimation();     
    const CTanimation = useAnimation(); 
    const IORanimation = useAnimation(); 
    const GRCanimation = useAnimation(); 




  useEffect(()=>{
    console.log("use effect hook, inView =", inView);
    
      if(inView){
        paraanimation.start({
          y:0,opacity:1,
          transition:{
            duration:1, ease:"easeOut", delay:0.1
          }
        });
      }
      if(!inView){
        paraanimation.start({y:100, opacity:0.2})
      } 

      if(inView){
        headinganimation.start({
          y:0,opacity:1,
          transition:{
            duration:1, ease:"easeOut", delay:0.1
          }
        });
      }
      if(!inView){
        headinganimation.start({y:100, opacity:0})
      }  

      if(inView){
        slaanimation.start({
          x:0,opacity:1,
          transition:{
            duration:1, ease:"easeInOut", delay:0.1
          }
        });
      }
      if(!inView){
        slaanimation.start({x:-40,  opacity:0})
      }  

      if(inView){
        MSSanimation.start({
          x:0, opacity:1,
          transition:{
            duration:1, ease:"easeInOut"
          }
        });
      }
      if(!inView){
        MSSanimation.start({x:-40,  opacity:0})
      }  

      if(inView){
        PSanimation.start({
          x:0 ,opacity:1,
          transition:{
            duration:1, ease:"easeInOut"
          }
        });
      }
      if(!inView){
        PSanimation.start({x:20, opacity:0})
      }  
      if(inView){
        CTanimation.start({
          x:0 ,opacity:1,
          transition:{
            duration:1, ease:"easeInOut"
          }
        });
      }
      if(!inView){
        CTanimation.start({x:20, opacity:0})
      }  
      if(inView){
        IORanimation.start({
          x:0 ,opacity:1,
          transition:{
            duration:1, ease:"easeInOut", delay:0.1
          }
        });
      }
      if(!inView){
        IORanimation.start({x:40, opacity:0})
      }  
      if(inView){
        GRCanimation.start({
          x:0 ,opacity:1,
          transition:{
            duration:1, ease:"easeInOut", delay:0.1
          }
        });
      }
      if(!inView){
        GRCanimation.start({x:40, opacity:0})
      }  
      
      
    console.log("use effect hook, inView =", inView);
  }, [inView, headinganimation, paraanimation, slaanimation, MSSanimation, PSanimation, CTanimation, IORanimation, GRCanimation]);  

    return (
        <div className={classes.serviceContainer} id="services">
            <Grid container direction="row" justify="center" alignItems="center" >

              <Grid container item xl={6} lg={6} md={6} sm={6} xs={6} justify="center" style={{marginTop:"2rem"}}>
                
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className={classes.circleGrid}>
                <motion.div ref={ref} animate={slaanimation} className={classes.circleCard}>
                    <motion.div style={{textAlign:"center"}}>
                    SLA (Service Level Agreements)
                    </motion.div>
                  </motion.div>
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className={classes.circleGrid2}>
                <motion.div ref={ref} animate={MSSanimation} className={classes.circleCard2}>
                    <motion.div style={{textAlign:"center"}}>
                    Managed SOC services
                    </motion.div>
                  </motion.div>
                </Grid>

                <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className={classes.circleGrid}>
                <motion.div ref={ref} animate={PSanimation} className={classes.circleCard}>
                    <motion.div style={{textAlign:"center"}}>
                    Professional Services
                    </motion.div>
                  </motion.div>
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className={classes.circleGrid2}>
                <motion.div ref={ref} animate={CTanimation} className={classes.circleCard2}>
                    <motion.div style={{textAlign:"center"}}>
                    Corporate Trainings
                    </motion.div>
                  </motion.div>
                </Grid>

                <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className={classes.circleGrid}>
                <motion.div ref={ref} animate={IORanimation} className={classes.circleCard}>
                    <motion.div style={{textAlign:"center"}}>
                    GRC Platform Implementation
                    </motion.div>
                  </motion.div>
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className={classes.circleGrid2}>
                <motion.div ref={ref} animate={GRCanimation} className={classes.circleCard2}>
                    <motion.div style={{textAlign:"center"}}>
                    International Outsourcing of Resources
                    </motion.div>
                  </motion.div>
                </Grid>

                
              </Grid>

              <Grid container item xl={5} lg={5} md={11} sm={11} xs={11}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.serviceContentGrid}>
                  <div style={{backgroundColor:""}}>
                  <motion.div ref={ref} animate={headinganimation} className={classes.serviceHead}>
                      Service We Offer
                    </motion.div>

                    <motion.div ref={ref} animate={paraanimation}className={classes.serviceContent}>
                    Our ingenious idea to change the landscape of IT structure was conceived in 2008, 
                    when we first opened our doors as an in-house infrastructure and help desk service provider. 
                    Over the years we have evolved as the most cost-effective and premier IT consultancy, 
                    due to our deep rooted expertise in technical knowledge, 
                    which we cater through our customer-centric approach.
                    </motion.div>
                  </div>
                </Grid>
              </Grid>
            
                {/* <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>

                    <div className={classes.serviceCard}>
                        <Grid  item xl={11} lg={11} md={11} sm={12} xs={12} className={classes.serviceGrid2} >
                            <div className={classes.serviceCard2}>
                                <Grid container item  xl={5} lg={5} md={5} sm={5} xs={10}  style={{marginBottom:"2rem"}}>
                                    
                                    <div className={classes.serviceCard3}>
                                        <motion.div ref={ref} animate={slaanimation} className={classes.circleOuterCards}>
                                            <motion.div className={classes.circleCards}>
                                                    SLA (Service Level Agreements)
                                            </motion.div>
                                        </motion.div>
                                        <motion.div ref={ref} animate={MSSanimation} className={classes.circleOuterCards} >
                                            <motion.div className={classes.circleCards}>
                                                    Managed SOC services
                                            </motion.div>
                                        </motion.div>
                                    </div> 

                                    <div className={classes.serviceCard4}>
                                        <motion.div ref={ref} animate={PSanimation} className={classes.circleOuterCards}>
                                            <div  className={classes.circleCards}>
                                                Professional Services
                                            </div>
                                        </motion.div>
                                            <motion.div ref={ref} animate={CTanimation} className={classes.circleOuterCards} >
                                            <div className={classes.circleCards}>
                                                Corporate Trainings
                                            </div>
                                            </motion.div>
                                            
                                    </div> 
                        
                                    <div className={classes.serviceCard4}>
                                        <motion.div ref={ref} animate={IORanimation} className={classes.circleOuterCards}>
                                            <motion.div className={classes.circleCards}>
                                            International Outsourcing of Resources
                                            </motion.div>
                                        </motion.div>
                                            <motion.div ref={ref} animate={GRCanimation} className={classes.circleOuterCards} >
                                            <motion.div className={classes.circleCards}>
                                                 GRC Platform Implementation
                                            </motion.div>
                                            </motion.div>
                                            
                                    </div> 
                                    </Grid>

                
                                <Grid container item  justify="center" xl={5} lg={5} md={10} sm={10} xs={10}>
                                <Card className={classes.serviceTextCard}> 
                                    <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className={classes.textGrid1}>
                                        <motion.div ref={ref} animate={headinganimation} className={classes.textMainHead}>
                                            Services We Offer
                                        </motion.div>
                                        
                                        <motion.div ref={ref} animate={paraanimation} className={classes.textPara}>
                                        Our ingenious idea to change the landscape of IT structure was conceived in 2008, 
                                        when we first opened our doors as an in-house infrastructure and help desk service provider. 
                                        Over the years we have evolved as the most cost-effective and premier IT consultancy, 
                                        due to our deep rooted expertise in technical knowledge, 
                                        which we cater through our customer-centric approach.
                                        </motion.div>
                                    </Grid>

                                </Card>
                                </Grid>
                            </div>
                        </Grid>
                    </div>
                </Grid> */}

            </Grid>
        </div>
    )
}

export default ServiceOffer

import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo-mutex-new.png";
import {Link} from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    logocard: {
        backgroundColor: "transparent",
        // backgroundColor: "pink",
        display: "flex",
        justifyContent: "center",
        border: "none",
        boxShadow: "none",
        height: "auto",
        width: "20%",
        position:"absolute",
        marginTop:"1rem"
    
      },
}));
function LogoMutex() {
    const classes = useStyles();

    return (
        <div className={classes.logocard}>
            <Link to="/">
              <img src={logo} className={classes.logoimg} alt="logo_image" width="100%" height="100%" />
            </Link>
        </div>
    )
}

export default LogoMutex

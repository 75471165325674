import React, {useEffect}  from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, } from "@material-ui/core";
import Slider from "react-slick";
//images import
import fw1 from "./Images/clients/Afiniti-logo.png"
import fw2 from "./Images/clients/alkaram.png"
import fw3 from "./Images/clients/askari-Logo.png"
import fw4 from "./Images/clients/alfalah-logo.png"
import fw5 from "./Images/clients/bankalhabiba.svg"
import fw6 from "./Images/clients/clover.svg"
import fw7 from "./Images/clients/dp-logo.png"
import fw8 from "./Images/clients/Pak-Oman-Investment-logo.png"
import fw9 from "./Images/clients/EOBI-logo.png"
import fw10 from "./Images/clients/fossil.svg"
import fw11 from "./Images/clients/getzpharma.svg"
import fw12 from "./Images/clients/hoora_pharma.svg"
import fw13 from "./Images/clients/iqra.svg"
import fw14 from "./Images/clients/itim.svg"
import fw15 from "./Images/clients/keep truckin.svg"
import fw16 from "./Images/clients/m&p.svg"
import fw17 from "./Images/clients/market 786.svg"
import fw18 from "./Images/clients/mds-logo.png"
import fw19 from "./Images/clients/media-logo.png"
import fw20 from "./Images/clients/NBP-logo.png"
import fw21 from "./Images/clients/ned-logo.png"
import fw22 from "./Images/clients/nexus.svg"
import fw23 from "./Images/clients/Novo-logo.png"
import fw24 from "./Images/clients/nust.png"
import fw25 from "./Images/clients/ea1.png"
import fw26 from "./Images/clients/Red-logo.png"
import fw27 from "./Images/clients/silkbank.svg"
import fw28 from "./Images/clients/sindh-Logo.png"
import fw29 from "./Images/clients/telen.svg"
import fw30 from "./Images/clients/ubl.svg"
import fw31 from "./Images/clients/united-food-limited.png"
import fw32 from "./Images/clients/zong.svg"
import fw33 from "./Images/clients/Red-logo.png"
import fw34 from "./Images/clients/rapid7-logo.png"
import fw35 from "./Images/clients/SABIC-logo.png"
import fw36 from "./Images/logos/vmware.svg"
// import fw37 from "./Images/logos/sangforLogo.svg"
// import fw38 from "./Images/logos/trendLogo.svg"
// import fw39 from "./Images/logos/symantecLogo.svg"
// import fw40 from "./Images/logos/citrix.svg"
// import fw41 from "./Images/logos/sonicLogo.svg"
// import fw42 from "./Images/logos/forcepointLogo.svg"
import fw43 from "./Images/clients/chinatree.png"
import fw44 from "./Images/clients/burma-oil.svg"
import fw45 from "./Images/clients/dynamic.svg"
import fw46 from "./Images/clients/ey.png"
import fw47 from "./Images/clients/faysal-bank.svg"
import fw48 from "./Images/clients/first-womn.jpg"
import fw49 from "./Images/clients/gfk-etilize.svg"
import fw50 from "./Images/clients/hbl-logo.png"
import fw51 from "./Images/clients/islamia-uni.jpg"
import fw53 from "./Images/clients/k-electric.svg"
import fw54 from "./Images/clients/khushali-bank.svg"
import fw55 from "./Images/clients/leopard.png"
import fw56 from "./Images/clients/lottie-chemical.jpg"
import fw57 from "./Images/clients/mon-salva.svg"
import fw58 from "./Images/clients/pak-qatar.jpg"
import fw59 from "./Images/clients/pak-shaheen.svg"
import fw60 from "./Images/clients/pakistan-exchange.jpg"
import fw61 from "./Images/clients/meezan-bank.svg"
import fw62 from "./Images/clients/pmrc.svg"
import fw63 from "./Images/clients/r&i.gif"
import fw64 from "./Images/clients/statebank.svg"
import fw65 from "./Images/clients/syntec-fibre.svg"
import fw66 from "./Images/clients/szabist.svg"
import fw67 from "./Images/clients/tcs.svg"
import fw68 from "./Images/clients/thai-eng.svg"
import fw69 from "./Images/clients/ufone.svg"
import fw70 from "./Images/clients/zeeshan.svg"
import fw71 from "./Images/clients/m&m.png"
import fw72 from "./Images/clients/ss&c.png"
import fw73 from "./Images/clients/naveena.webp"
import fw74 from "./Images/clients/vcon.png"
import fw75 from "./Images/clients/samaa.png"
import fw76 from "./Images/clients/union.jpg"



import {useInView} from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion";

const useStyles = makeStyles(theme => ({
  aboutcontainer: {
    backgroundColor: "#1e2020",
  },

  heading: {
    width: "fit-content",
    //    backgroundColor:"#1e2020"
    backgroundColor: "pink",
  },
  mainheading: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "5vmax",
    textAlign: "center",
    color: "#129674",
    backgroundImage:
      "linear-gradient(0deg, rgb(6,165,103) 10%, rgb(28,138,126) 100%)",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozBackgroundClip: "text",
    mozTextFillColor: "transparent",
  },
  mainHeadCard: {
    backgroundColor: "transparent",
    // backgroundColor:'pink',
    width: "fit-content",
    alignSelf: "center",
    position: "absolute",
  },

  bgheading: {
    color: "#767574 ",
    fontSize: "8.2vmax",
    fontFamily: "Gotham",
    textTransform: "uppercase",
    textAlign:"center"
  },

  bgHeadCard: {
    backgroundColor: "transparent",
    // backgroundColor:'blue',
    opacity: "0.1",
    position: "relative",
  },

  bgHeadGrid: {
    // backgroundColor:'red',
    display: "flex",
    justifyContent:"center"
  },

//   dots:{
//         height:"4vh",
//         backgroundColor:"",
//       overflow:"hidden",        
//     "& li.slick-active button::before": {
//       color: '#0f8b66',
    
//     },
//     "& li": {
//       "& button::before": {
//       fontSize: theme.typography.pxToRem(18),
//       color: "white",
//       opacity: 1, 
//       width:"1rem",
//     },
//   },
// },

  shadow:{
    //    backgroundColor:'red',
       paddingTop:'0px',
      textAlign:'center ',
      backgroundColor:"#1e2020",
      marginTop:'10%',
      height:'10vh'
   },

   // ----------------------------------------------
  sliderCard:{
  // backgroundColor:'red',
  width:"70vw",
  },

  sliderImgDiv:{
    // backgroundColor:"blue", 
    width:"", 
    height:"4rem",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },

  logosDiv:{
    // backgroundColor:"black", 
    height:"2.7rem", 
    // height:"2.5rem", 
    width:"8rem", 
    display:"flex", 
    justifyContent:"center"
  },

  logos:{
    width:"100%",
    height:"100%",
    [theme.breakpoints.down("md")]: {
    width:"85%"
    },
  },
  // ------------------------------------------------

  fwdiv1:{
  // backgroundColor:"pink",
  display:"flex",
  justifyContent: "center",
  alignItems:"center",
  height:"4rem",
  padding:"5px",
  },


}));

function Clients() {

  const {ref, inView} = useInView();
  const animation = useAnimation();  
  const animation2 = useAnimation();

  const images = [
    {id: "1", image:fw1, alt:"Slider_image"},
    {id: "2", image:fw2, alt:"Slider_image"},
    {id: "3", image:fw3, alt:"Slider_image"},
    {id: "4", image:fw4, alt:"Slider_image"},
    {id: "5", image:fw5, alt:"Slider_image"},
    {id: "6", image:fw6, alt:"Slider_image"},
    {id: "7", image:fw7, alt:"Slider_image"},
    {id: "8", image:fw8, alt:"Slider_image"},
    {id: "9", image:fw9, alt:"Slider_image"},
    {id: "10", image:fw10, alt:"Slider_image"},
    {id: "11", image:fw11, alt:"Slider_image"},
    {id: "12", image:fw12, alt:"Slider_image"},
    {id: "13", image:fw13, alt:"Slider_image"},
    {id: "14", image:fw14, alt:"Slider_image"},
    {id: "15", image:fw15, alt:"Slider_image"},
    {id: "16", image:fw16, alt:"Slider_image"},
    {id: "17", image:fw17, alt:"Slider_image"},
    {id: "18", image:fw18, alt:"Slider_image"},
    {id: "19", image:fw19, alt:"Slider_image"},
    {id: "20", image:fw20, alt:"Slider_image"},
    {id: "21", image:fw21, alt:"Slider_image"},
    {id: "22", image:fw22, alt:"Slider_image"},
    {id: "23", image:fw23, alt:"Slider_image"},
    {id: "24", image:fw24, alt:"Slider_image"},
    {id: "25", image:fw25, alt:"Slider_image"},
    {id: "26", image:fw26, alt:"Slider_image"},
    {id: "27", image:fw27, alt:"Slider_image"},
    {id: "28", image:fw28, alt:"Slider_image"},
    {id: "29", image:fw29, alt:"Slider_image"},
    {id: "30", image:fw30, alt:"Slider_image"},
    {id: "31", image:fw31, alt:"Slider_image"},
    {id: "32", image:fw32, alt:"Slider_image"},
    {id: "33", image:fw33, alt:"Slider_image"},
    {id: "34", image:fw34, alt:"Slider_image"},
    {id: "35", image:fw35, alt:"Slider_image"},
    {id: "36", image:fw36, alt:"Slider_image"},
    {id: "43", image:fw43, alt:"Slider_image"},
    {id: "44", image:fw44, alt:"Slider_image"},
    {id: "45", image:fw45, alt:"Slider_image"},
    {id: "46", image:fw46, alt:"Slider_image"},
    {id: "47", image:fw47, alt:"Slider_image"},
    {id: "48", image:fw48, alt:"Slider_image"},
    {id: "49", image:fw49, alt:"Slider_image"},
    {id: "50", image:fw50, alt:"Slider_image"},
    {id: "51", image:fw51, alt:"Slider_image"},
    {id: "53", image:fw53, alt:"Slider_image"},
    {id: "54", image:fw54, alt:"Slider_image"},
    {id: "55", image:fw55, alt:"Slider_image"},
    {id: "56", image:fw56, alt:"Slider_image"},
    {id: "57", image:fw57, alt:"Slider_image"},
    {id: "58", image:fw58, alt:"Slider_image"},
    {id: "59", image:fw59, alt:"Slider_image"},
    {id: "60", image:fw60, alt:"Slider_image"},
    {id: "61", image:fw61, alt:"Slider_image"},
    {id: "62", image:fw62, alt:"Slider_image"},
    {id: "63", image:fw63, alt:"Slider_image"},
    {id: "64", image:fw64, alt:"Slider_image"},
    {id: "65", image:fw65, alt:"Slider_image"},
    {id: "66", image:fw66, alt:"Slider_image"},
    {id: "67", image:fw67, alt:"Slider_image"},
    {id: "68", image:fw68, alt:"Slider_image"},
    {id: "69", image:fw69, alt:"Slider_image"},
    {id: "70", image:fw70, alt:"Slider_image"},
    {id: "71", image:fw71, alt:"Slider_image"},
    {id: "72", image:fw72, alt:"Slider_image"},
    {id: "73", image:fw73, alt:"Slider_image"},
    {id: "74", image:fw74, alt:"Slider_image"},
    {id: "75", image:fw75, alt:"Slider_image"},
    {id: "76", image:fw76, alt:"Slider_image"},
  ]

  useEffect(()=>{
    console.log("use effect hook, inView =", inView);

    if(inView){
      animation.start({
        y:0,opacity:1,
        transition:{
          duration:1, ease:"easeOut", 
        }
      });
    }
    if(!inView){
      animation.start({y:100, opacity:0})
    }  

    if(inView){
      animation2.start({
        y:0,opacity:0.1,
        transition:{
          duration:1, ease:"easeInOut"
        }
      });
    }
    if(!inView){
      animation2.start({y:-20,  opacity:0})
    }

      
    console.log("use effect hook, inView =", inView);
});  
  const classes = useStyles();
  var settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow:6,
    autoplay: true,
    slidesToScroll: 2,
    adaptiveHeight:true,
    dotsClass: `slick-dots ${classes.dots}`,
  //   appendDots: dots => (
  //     <div
  //       style={{
  //         backgroundColor: "",
  //         borderRadius: "10px",
  //         display:"flex",
  //         justifyContent:"center"
          
  //       }}
  //     >
  //       <ul style={{ margin: "0px", width:"8rem", backgroundColor:"pink", }}> {dots} </ul>
  //     </div>
  //   ),
  //  custom: i => (
  //     <div> 
  //       {i + 0}
  //     </div>
  //   ),
    // arrows:true,
    variableWidth:true,
    responsive: [
    
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          // initialSlide: 2
        }
      },
      
      {
        breakpoint: 240,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <div id="clients">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.aboutcontainer}
      >
        <Grid
          item
          xl={10}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          className={classes.bgHeadGrid}
        >
          <motion.div ref={ref} animate={animation2} className={classes.bgHeadCard}>
            <Typography className={classes.bgheading}>Clients</Typography>
          </motion.div>
          <motion.div ref={ref} animate={animation} className={classes.mainHeadCard}>
            <Typography className={classes.mainheading}>Clients</Typography>
          </motion.div>
        </Grid>

        <Grid item xl={9} lg={9} md={9} sm={9} xs={9} >
        <div className={classes.sliderCard}>
        <Slider {...settings}>

      {images.map(data =>(
      <div className={classes.fwdiv1} key={data.id}>
        <div className={classes.sliderImgDiv}>
          <div className={classes.logosDiv}>
            <img src={data.image} alt={data.alt} className={classes.logos} height="100%" width="100%" />
          </div>
        </div>
      </div>
      ))}
     
     
    </Slider>
        </div>
        </Grid>

        <Grid item>
            <div className={classes.shadow}>
                
            </div>
        </Grid>

      </Grid>
    </div>
  );
}

export default Clients;

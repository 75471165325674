import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import backvideo from "./Videos/Web-Tec-1-(1).webm";
import "../App";

const useStyles = makeStyles((theme) => ({
  videocard: {
    // backgroundColor:'transparent',
    backgroundColor: "#1e2020",
    height: "100%",
    width: "100%",
    position: "absolute",
  },

  videoTag: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "-1",
  },
}));

function Videobg() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.videocard}>
        <video className={classes.videoTag} autoPlay loop muted>
          <source src={backvideo} type="video/webm" />
        </video>
      </div>
      <></>
    </>
  );
}

export default Videobg;

import React from "react";
import Particles from "react-particles-js";
import ParticleConfig from "../config/particle-config.js";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  particle: {
    width: "100%",
    height: "100%",
  },
  particleCard: {
    // backgroundColor:'red',
    height: "100%",
    width: "100%",
    position: "absolute",
  },
}));
function ParticlesComp() {
  const classes = useStyles();

  return (
    <div className={classes.particleCard}>
      <Particles
        params={ParticleConfig}
        className={classes.particle}
      ></Particles>
    </div>
  );
}

export default ParticlesComp;
